import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    Box,
    Button, Checkbox,
    Container, FormControl, FormControlLabel,
    FormLabel,
    Grid, InputAdornment,
    MenuItem, Paper, Radio,
    Select,
    Tabs
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {useOutletContext, useParams} from "react-router-dom";
import '../App.css';
import * as PropTypes from "prop-types";
import Tab from "@mui/material/Tab";

import BerlineCar from "../assets/cars/Berline.png";
import BerlineXLCar from "../assets/cars/BerlineXL.png";
import CitadineCar from "../assets/cars/Citadine.png";
import CoupeCar from "../assets/cars/Coupe.png";
import FamilialeCar from "../assets/cars/Familiale.png";
import FamilialeBigCar from "../assets/cars/GrosseFamiliale.png";
import SuvBigCar from "../assets/cars/GrosSuv.png";
import SuvSmallCar from "../assets/cars/petitSuv.png";
import LuxeSupercarCar from "../assets/cars/LuxeSupercar.png";
import MicroCar from "../assets/cars/Micro.png";


import AddCircleIcon from '@mui/icons-material/AddCircle';
import LocationServiceTab from "./LocationServiceTab";
import {withStyles} from "@mui/styles";
import LocationScheduleTab from "./LocationScheduleTab";
import LocationCalendar from "./LocationCalendar";

function LocationSchedules(props, ref) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');
    const token = localStorage.getItem('accessToken');

    const [locationId, setLocationId] = useState(null);

    let params = useParams();

    const [locationData, setLocationData] = useState(null);
    const [schedulesData, setSchedulesData] = useState([]);

    // For tabs
    const [value, setValue] = React.useState(0);
    const tabChangedHandler = (event, newValue) => {
        // console.log("New value = " + newValue);
        setValue(newValue);
    };

    let newDataCount = 0;

    const schedulesDataRef = useRef(null);
    schedulesDataRef.current = schedulesData;

    const locationDataRef = useRef(null);
    locationDataRef.current = locationData;

    const locationIdRef = useRef(null);
    locationIdRef.current = locationId;

    const calendarRef = useRef();

    const editModeRef = useRef(null);
    editModeRef.current = props.isEditMode;

    const weekDays = [
        {
            'id': 'mon',
            'label': t('weekDays.condensed.1')
        },
        {
            'id': 'tue',
            'label': t('weekDays.condensed.2')
        },
        {
            'id': 'wed',
            'label': t('weekDays.condensed.3')
        },
        {
            'id': 'thu',
            'label': t('weekDays.condensed.4')
        },
        {
            'id': 'fri',
            'label': t('weekDays.condensed.5')
        },
        {
            'id': 'sat',
            'label': t('weekDays.condensed.6')
        },
        {
            'id': 'sun',
            'label': t('weekDays.condensed.7')
        },
    ];

    useEffect(() => {
        if (params.locationId != null) {
            getFromServer(params.locationId);
        }

        props.previous_btn.current.style.display = 'none';
        // props.previous_btn.current.addEventListener('click', () => console.log('back'));

        props.next_btn.current.addEventListener('click', nextClickedHandler);

        return () => {
            props.next_btn.current && props.next_btn.current && props.next_btn.current.removeEventListener('click', nextClickedHandler);
        }
    }, [])

    useImperativeHandle(ref, () => ({
        wantChangeTab(stepValue) {
            console.log('want change tab to ' + stepValue);
            // TODO SAve or warn..
            props.changeStepFromContentHandler(stepValue);
        },
    }));

    function nextClickedHandler() {
        if (!checkCapacities()) {
            return;
        }

        if (!checkSchedules()) {
            Swal.fire({
                text: t('location.schedules.warning_schedule_incoherence'),
                icon: 'warning',
                customClass: {
                    container: 'my-swal'
                }
            });
            return;
        }

        let data = {
            id: locationIdRef.current,
            schedules: schedulesDataRef.current
        }

        // console.log(servicesDataRef.current);
        // console.log("DATA to server :");
        // console.log(data);

        sendToServer(data);
    }

    // TODO redondant
    async function getFromServer(id) {
        return fetch(apiUri + 'location_get/' + id, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(data => data.json())
            .then(data => {
                if (data.state === 'OK') {
                    setLocationId(data.location.locationId);
                    setLocationData(data.location);
                    locationDataRef.current = data.location;
                    if (data.location.schedules.length == 0) {
                        setSchedulesData(createSchedules());
                    } else {
                        setSchedulesData(data.location.schedules);
                    }
                    schedulesDataRef.current = data.location.schedules;

                    props.contentLoadedHandler(data.location);
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            })
    }

    //
    async function sendToServer(data) {
        return fetch(apiUri + 'location_schedules', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body:
                JSON.stringify(data)
        })
            .then(data => data.json())
            .then(data => {
                if (data.state === 'OK') {
                    // We have a calendar
                    if (editModeRef.current) {
                        calendarRef.current.save();
                    } else {
                        Swal.fire({
                            text: t('location.info.success'),
                            icon: 'success',
                            timer: 2000,
                            customClass: {
                                container: 'my-swal'
                            }
                        })
                            .then(() => props.changeStepFromContentHandler(-1))

                    }
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            })
    }

    function calendarSaveSuccessHandler() {
        if (!editModeRef.current) {
            props.changeStepFromContentHandler(-1);
        }
    }

    // TODO ? Consolidate existing ?
    function createSchedules() {
        weekDays.forEach((weekDay, index) => {
            newDataCount++;
            let newScheduleData = {
                "id": "N" + newDataCount,
                "weekDay": weekDay.id,
                "amStart": t('location.schedules.default_from_am'),
                "amEnd": t('location.schedules.default_to_am'),
                "amCapacity": null,
                "amClosed": index == 6 ? 1 : 0,
                "apStart": t('location.schedules.default_from_pm'),
                "apEnd": t('location.schedules.default_to_pm'),
                "apCapacity": null,
                "apClosed": index == 6 ? 1 : 0,
            }
            schedulesData.push(newScheduleData);
        });
        return schedulesData;
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function refreshData() {
        console.log("refreshData");
        // let newSchedulesData = schedulesData.slice(0);
        // setSchedulesData(newSchedulesData);
        setSchedulesData(JSON.parse(JSON.stringify(schedulesData)));
    }

    function applyAllHandler(scheduleDataToCopy) {

        Swal.fire({
            title: t('location.schedules.apply_to_all_confirmation'),
            showDenyButton: true,
            confirmButtonText: t('common.yes'),
            denyButtonText: t('common.no'),
            customClass: {
                container: 'my-swal'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                let newSchedulesData = schedulesData.map(scheduleData => {
                    // console.log(scheduleDataToCopy);
                    scheduleData.amStart = scheduleDataToCopy.amStart;
                    scheduleData.amEnd = scheduleDataToCopy.amEnd;
                    scheduleData.amCapacity = scheduleDataToCopy.amCapacity;
                    scheduleData.amClosed = scheduleDataToCopy.amClosed;
                    scheduleData.apStart = scheduleDataToCopy.apStart;
                    scheduleData.apEnd = scheduleDataToCopy.apEnd;
                    scheduleData.apCapacity = scheduleDataToCopy.apCapacity;
                    scheduleData.apClosed = scheduleDataToCopy.apClosed;
                    return scheduleData;
                });
                setSchedulesData(newSchedulesData);
            } else if (result.isDenied) {
                return;
            }
        })
    }

    function isOpen(weekDay) {
        let schedule = schedulesData.find(scheduleData => {
            return scheduleData.weekDay == weekDay.id;
        });

        if (schedule == null) {
            return false;
        }
        return (schedule.amClosed != 1 || schedule.apClosed != 1);
    }

    function checkSchedules() {
        let ok = true;
        // console.log(schedulesData);
        schedulesDataRef.current.forEach(scheduleData => {
            // am
            if (!(scheduleData.amClosed == 1)) {
                console.log(scheduleData.amStart + "=>" + scheduleData.amEnd);
                if (scheduleData.amStart > scheduleData.amEnd) {
                    ok = false;
                }
            }
            // pm
            if (!scheduleData.apClosed) {
                if (scheduleData.apStart > scheduleData.apEnd) {
                    ok = false;
                }
            }
            // am + pm
            if (!scheduleData.amClosed && !scheduleData.apClosed) {
                if (scheduleData.amEnd > scheduleData.apStart) {
                    ok = false;
                }
            }
        });
        return ok;
    }

    function checkCapacities() {
        console.log(schedulesDataRef.current);
        let ok = true;
        let days = [];
        schedulesDataRef.current.forEach((scheduleData, index) => {
            if ((scheduleData.amClosed == 0 && isNotSet(scheduleData.amCapacity)) || (scheduleData.apClosed == 0 && isNotSet(scheduleData.apCapacity))) {
                ok = false;
                days[index] = true;
            }
        });
        if (!ok) {
            let text = t('location.schedules.error_null_capacity');
            days.forEach((w, index) => {
                text += weekDays[index].label;
            });
            Swal.fire({
                text: text,
                icon: 'warning',
                customClass: {
                    container: 'my-swal'
                }
            });
        }
        return ok;
    }

    function isNotSet(field) {
        return field == null || field == undefined || isNaN(field) || field == '';
    }

    return (
        <Container maxWidth={false}>
            <Container maxWidth={false}>
                {schedulesData.length > 0 &&
                <>
                    {/*<BasicTabs/>*/}
                    <Grid container justifyContent="center">
                        <Grid container
                              sx={{maxWidth: 700, minWidth: 700, backgroundColor: '#292e32', marginTop: '20px'}}>
                            <Grid sx={{borderBottom: 1, borderColor: 'divider', width: '100vh'}}>
                                <Tabs
                                    variant="fullWidth"
                                    centered
                                    value={value} onChange={tabChangedHandler}>
                                    {weekDays.map((weekDay, index) => {
                                        return <Tab sx={{
                                            backgroundColor: isOpen(weekDay) && "#FFC70F",
                                            color: isOpen(weekDay) && "black"
                                        }} key={index}
                                                    label={weekDay.label} {...a11yProps(index)} />
                                    })
                                    }
                                </Tabs>
                            </Grid>
                            <Grid sx={{width: '100vh'}}>
                                {schedulesData.map((scheduleData, index) => {
                                    return <LocationScheduleTab
                                        key={index}
                                        currentTabIndex={value}
                                        index={index}
                                        scheduleData={scheduleData}
                                        applyAllHandler={applyAllHandler}
                                        refreshDataHandler={refreshData}
                                    />
                                })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </>}
            </Container>
            {locationId != null && props.isEditMode &&
            <LocationCalendar ref={calendarRef} locationId={locationId} schedulesData={schedulesData}
                              saveSuccessHandler={calendarSaveSuccessHandler}/>
            }
        </Container>
    )
};


export default forwardRef(LocationSchedules);