import {useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Box, Button, Container, FormControlLabel, FormGroup, Grid, Switch} from "@mui/material";
import Swal from "sweetalert2";
import LocationScheduleRow from "./LocationScheduleRow";

// scheduleData => jour habituel
// calendarDayData => jour exceptionnel

function LocationCalendarDay({calendarDayData, dataChangedHandler, scheduleData}) {
    const [apiUri] = useOutletContext();
    const {t, i18n} = useTranslation('common');
    const token = localStorage.getItem('accessToken');
    const [isException, setIsException] = React.useState(false)

    useEffect(() => {
        setIsException(isAnException());
    }, [calendarDayData])

    function formatDayDate(sqlDate) {
        let date = new Date(sqlDate);
        const DATE_OPTIONS = {weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'};
        let str = date.toLocaleDateString(i18n.language, DATE_OPTIONS);
        str = str.toUpperCase();
        return str;
    }

    function isAnException() {

        if (!('schedulesExceptionId' in calendarDayData)) {
            return false;
        }
        // console.log(calendarDayData.schedulesExceptionId);
        return calendarDayData.schedulesExceptionId.toString().charAt(0) != 'D';
    }

    function rowDataChangedHandler(dispatchDataChanged = true) {
        // setIsException(true);

        // Nouvelle exception ?
        if (!isAnException()) {
            calendarDayData.schedulesExceptionId = 'N';
            calendarDayData.amClosed = scheduleData.amClosed;
            calendarDayData.amStart = scheduleData.amStart;
            calendarDayData.amEnd = scheduleData.amEnd;
            calendarDayData.amCapacity = scheduleData.amCapacity;
            calendarDayData.apClosed = scheduleData.apClosed;
            calendarDayData.apStart = scheduleData.apStart;
            calendarDayData.apEnd = scheduleData.apEnd;
            calendarDayData.apCapacity = scheduleData.apCapacity;
            calendarDayData.isFull = '0';
        }

        // console.log(scheduleData);

        if (dispatchDataChanged) {
            dataChangedHandler();
        }
    }

    function cancelExceptionClickedHandler(e) {

        if (calendarDayData.schedulesExceptionId == 'N') {
            // Nouvelle exception => on la supprime
            delete calendarDayData.schedulesExceptionId;
        } else {
            calendarDayData.schedulesExceptionId = 'D' + calendarDayData.schedulesExceptionId;
        }
        dataChangedHandler();
    }

    let borderColor = 'divider';
    if (isException) {
        borderColor = 'orange';
    }

    let isFull = isAnException() && calendarDayData.isFull == '1';
    let isToday = ('isToday' in calendarDayData) && calendarDayData.isToday == '1';

    return (
        <>
            <Box sx={{
                m: 1,
                borderRadius: '8px',
                height: '24px',
                backgroundColor: isToday ? 'orange' : ''
            }}>
                <Grid container justifyContent="center">
                    {
                        isToday ? t('location.calendar.today_label') : ''
                    }
                </Grid>
            </Box>
            <Box sx={
                {
                    m: 1,
                    border: 3,
                    borderColor: {borderColor},
                    borderRadius: '10px',
                    backgroundColor: '#292e32'
                }
            }>
                <Grid container justifyContent="center" sx={
                    {
                        p: 1,
                        backgroundColor: 'black'
                    }
                }>{formatDayDate(calendarDayData.dateDay)}
                    {isAnException() &&
                    <Button
                        variant="contained"
                        color="yellow"
                        onClick={cancelExceptionClickedHandler}
                    >
                        {t('location.calendar.button_cancel_exception')}
                    </Button>
                    }
                </Grid>
                <LocationScheduleRow shortVersion={true} scheduleData={isException ? calendarDayData : scheduleData}
                                     type="am"
                                     dataChangedHandler={rowDataChangedHandler}/>
                <Grid sx={{borderBottom: 1, borderColor: 'divider', marginTop: "10px", marginBottom: '10px'}}/>
                <LocationScheduleRow shortVersion={true} scheduleData={isException ? calendarDayData : scheduleData}
                                     type="pm"
                                     dataChangedHandler={rowDataChangedHandler}/>

                <Grid container justifyContent="center" sx={
                    {
                        backgroundColor: isFull ? 'orange' : ''
                    }
                }>
                    <FormGroup><FormControlLabel control={<Switch
                        checked={isFull}
                        onChange={function (e) {
                            rowDataChangedHandler(false);
                            calendarDayData.isFull = e.target.checked ? '1' : '0';
                            rowDataChangedHandler();
                        }}/>} label={t('location.calendar.full_label')}/></FormGroup>
                </Grid>
            </Box>
        </>
    );
}

export default LocationCalendarDay;