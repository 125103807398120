import React, {useState} from 'react';
import Swal from 'sweetalert2'

import logo from '../assets/logo-header.png';
import {Button, Container, Grid, TextField, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Link, Navigate, useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Login(props) {
    const classes = useStyles();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [apiUri] = useOutletContext();
    const {t, i18n} = useTranslation('common');
    const [loginDone, setLoginDone] = useState(false);
    const [accountType, setAccountType] = useState(null);

    async function loginUser(credentials) {
        return fetch(apiUri + 'login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json())
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const response = await loginUser({
            email,
            password
        });
        if ('api_token' in response) {
            Swal.fire(t('login.success.title'), t('login.success.desc'), "success", {
                buttons: false,
                timer: 2000,
            })
                .then((value) => {
                    localStorage.setItem('accessToken', response['api_token']);
                    localStorage.setItem('accountType', response['accountType']);   // Necessaire au menu
                    setAccountType(response.accountType);
                    setLoginDone(true);
                });
        } else {
            // response.message
            Swal.fire(t('common.error_title'), t(response.message), "error");
        }
    }

    let redirect;
    if (loginDone) {
        if (accountType == 1) {
            redirect = <Navigate to='/vehicle'/>;
        } else if (accountType == 2) {
            redirect = <Navigate to='/dashboard'/>;
        }
    }

    return (<>
            {redirect ?
                redirect
                :
                <Grid container
                      alignItems="center"
                      justifyContent="center">

                    {/* Login Form  */}
                    <Container maxWidth='sm' className={classes.loginContainer} sx={{borderRadius: '16px'}}
                               disableGutters>
                        <Container>
                            <img src={logo} className={classes.logo}/>
                        </Container>
                        <Container>
                            <Typography align='center' variant='subtitle1'>
                                {t('login.title')}
                            </Typography>
                            <div>
                                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        name="email"
                                        label={t('login.email')}
                                        onChange={e => setEmail(e.target.value)}
                                        InputProps={{
                                            className: classes.input
                                        }}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="password"
                                        name="password"
                                        label={t('login.password')}
                                        type="password"
                                        onChange={e => setPassword(e.target.value)}
                                    />
                                    <Link to="/reset_password"
                                          className={classes.link}>{t('login.reset_password')}</Link>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="yellow"
                                        className={classes.loginBtn}
                                        sx={{mt: '15px', mb: '20px'}}
                                    >
                                        {t('login.btn_login')}
                                    </Button>
                                </form>
                            </div>
                        </Container>
                        <Container maxWidth='sm' className={classes.registerContainer}
                                   sx={{borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px'}}>
                            <Link to="/register" style={{textDecoration: 'none'}}>
                                <Button
                                    margin="normal"
                                    fullWidth
                                    color="yellow"
                                >
                                    {t('login.btn_register')}
                                </Button>
                            </Link>
                        </Container>
                    </Container>
                </Grid>} </>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        // backgroundColor: '#49545d',  // Gris clair 1
        background: 'linear-gradient(#49545D, #1C2326)'
    },
    input: {
        color: '#49545d'  // gris clair 2
    },
    loginContainer: {
        backgroundColor: '#292e32'
    },
    registerContainer: {
        backgroundColor: '#1d2124', // Gris foncé 1
        borderTop: 'solid #373B3E',
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    logo: {
        width: '90%',
        margin: '20px'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    loginBtn: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#fbc70f',
        color: 'black',
    },
    white: {
        color: 'white'
    },

    centerText: {
        alignItems: "center",
        display: "flex"
    },
    link: {
        color: 'white'
    }
}));