import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import CheckoutForm from './CheckoutForm';
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";
import {useLocation, useOutletContext} from "react-router-dom";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST);

export default function TestStripe() {

    const token = localStorage.getItem('accessToken');
    const {t} = useTranslation('common');
    const [apiUri] = useOutletContext();

    const location = useLocation();
    const [clientSecret, setClientSecret] = useState(null);

    useEffect(() => {

        if (location.pathname == "/test_stripe_callback") {
            Swal.fire("Paiement réussi !", '', 'success', {timer: 2000});
            return;
        }
        getPaymentIntent();
    }, [])

    async function getPaymentIntent() {
        return fetch(apiUri + 'test_payment', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(data => data.json())
            .then(data => {
                if (data.state == 'OK') {
                    console.log(data.message.client_secret);
                    setClientSecret(data.message.client_secret);
                    // setLoaded(true);
                    // setVehicleList(data.vehicles);
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            })
    }


    const options = {
        // passing the client secret obtained in step 2
        clientSecret: clientSecret,
        // Fully customizable with appearance API.
        // appearance: {/*...*/},
    };

    if (clientSecret != null) {
        // return (<>></>);
        return (
            <Elements stripe={stripePromise} options={options}>
                <CheckoutForm/>
            </Elements>
        );
    } else {
        return <>Récupération du client secret...</>
    }

};