import React, {useState} from 'react';
import {
    AppBar,
    Card,
    CardContent,
    Divider, Grid,
    Toolbar,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";
import logo from "../assets/logo-header.png";
import ImageButton from "./ImageButton";

import btnProfile from "../assets/menu/profile/profil-b-g.png";
import btnProfileOver from "../assets/menu/profile/profil-b-j.png";
import btnNotification from "../assets/menu/notification/notification-b-g.png";
import btnNotificationOver from "../assets/menu/notification/notification-b-j.png";
import btnDashboard from "../assets/menu/dashboard/dashboard-b-g.png";
import btnDashboardOver from "../assets/menu/dashboard/dashboard-b-j.png";
import btnLocation from "../assets/menu/location/locations-alt-3.png";

import {useTranslation} from "react-i18next";

export default function LoggedLayout(props) {
    const classes = useStyles();
    const {t} = useTranslation('common');
    const [label, setLabel] = useState();

    const accountType = localStorage.getItem('accountType');

    return (
        <div className={classes.root}>
            <AppBar position="static" sx={{bgcolor: "black"}}>
                <Toolbar>
                    <img src={logo} className={classes.logo}/>
                    <Grid container alignItems="center" justifyContent="flex-end">
                        <div className={classes.menuPage}>{t(label)}</div>
                        <Divider orientation="vertical" variant="middle" className={classes.divider} flexItem/>
                        <div style={{marginLeft: '20px'}}></div>

                        {accountType == 1 &&
                        <Link to="/vehicle">
                            <ImageButton src={btnDashboard} srcOver={btnDashboardOver}/>
                        </Link>
                        }

                        {accountType == 2 &&
                        <>
                            <Link to="/dashboard">
                                <ImageButton src={btnDashboard} srcOver={btnDashboardOver}/>
                            </Link>
                            <Link to="/location">
                                <ImageButton src={btnLocation} srcOver={btnLocation}/>
                            </Link></>
                        }
                        <Link to="/profile">
                            <ImageButton src={btnProfile} srcOver={btnProfileOver}/>
                        </Link>
                        <ImageButton src={btnNotification} srcOver={btnNotificationOver}/>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Card className={classes.root} variant="outlined">
                <CardContent className={classes.cardContent}>
                    <Grid justifyContent="center" alignItems="center" container>
                        {React.cloneElement(props.children, {setLabel: setLabel})}
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#292e32'
    },
    cardContent: {
        backgroundColor: '#292e32'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    large: {
        width: theme.spacing(20),
        height: theme.spacing(20),
    },
    logo: {
        height: '100px',
        margin: '10px'
    },
    menuBtnImg: {
        height: '50px'
    },
    divider: {
        background: theme.palette.yellow.main,
    },
    menuPage: {
        color: theme.palette.yellow.main,
        paddingRight: '20px'
    }
}));
