import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import App from './App';
import Login from "./components/Login";
import Register from "./components/Register";
import Profile from "./components/Profile";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import common_en from "./translations/en/common.json";
import common_fr from "./translations/fr/common.json";
import EmailConfirmation from "./components/EmailConfirmation";
import ResetPassword from "./components/ResetPassword";
import ResetPasswordNewPassword from "./components/ResetPasswordNewPassword";
import LoggedLayout from "./components/LoggedLayout";
import Dashboard from "./components/Dashboard";
import ProfileStripe from "./components/ProfileStripe";
import LocationIndex from "./components/LocationIndex";
import LocationInfo from "./components/LocationInfo";
import LocationModal from "./components/LocationModal";
import LocationGeocode from "./components/LocationGeo";
import LocationServices from "./components/LocationServices";
import LocationSchedules from "./components/LocationSchedules";
import VehicleIndex from "./components/VehicleIndex";
import VehicleModal from "./components/VehicleModal";
import VehicleStep1 from "./components/VehicleStep1";
import VehicleStep2 from "./components/VehicleStep2";
import TestStripe from "./components/TestStripe";

let language = localStorage.getItem('language');
if (language == null) {
    language = 'fr';
}

i18next.init({
    interpolation: {escapeValue: false},  // React already does escaping
    lng: language,                              // language to use
    resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        fr: {
            common: common_fr
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <I18nextProvider i18n={i18next}>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App/>}>
                    <Route path="login" element={<Login/>}/>
                    <Route path="register" element={<Register/>}/>
                    <Route path="email_validation" element={<EmailConfirmation/>}/>
                    <Route path="reset_password" element={<ResetPassword/>}/>
                    <Route path="reset_password_new_password" element={<ResetPasswordNewPassword/>}/>

                    <Route path="dashboard" element={<LoggedLayout children={<Dashboard/>}></LoggedLayout>}/>
                    <Route path="profile" element={<LoggedLayout children={<Profile/>}></LoggedLayout>}/>
                    <Route path="profile_stripe" element={<LoggedLayout children={<ProfileStripe/>}></LoggedLayout>}/>
                    <Route path="stripe_callback" element={<LoggedLayout children={<ProfileStripe/>}></LoggedLayout>}/>
                    <Route path="location/" element={<LoggedLayout children={<LocationIndex/>}></LoggedLayout>}>
                        <Route path="add"
                               element={<LocationModal step="0" children={<LocationGeocode/>}></LocationModal>}/>
                        <Route path="edit/:locationId"
                               element={<LocationModal step="0" children={<LocationGeocode/>}></LocationModal>}/>
                        <Route path="info/:locationId"
                               element={<LocationModal step="1" children={<LocationInfo/>}></LocationModal>}/>
                        <Route path="services/:locationId"
                               element={<LocationModal step="2" children={<LocationServices/>}></LocationModal>}/>
                        <Route path="schedules/:locationId"
                               element={<LocationModal step="3" children={<LocationSchedules/>}></LocationModal>}/>
                    </Route>
                    <Route path="vehicle/" element={<LoggedLayout children={<VehicleIndex/>}></LoggedLayout>}>
                        <Route path="add"
                               element={<VehicleModal step="0" children={<VehicleStep1/>}></VehicleModal>}/>
                        <Route path="step1/:vehicleId"
                               element={<VehicleModal step="0" children={<VehicleStep1/>}></VehicleModal>}/>
                        {/* NB : util uniquement pour bien identifier qu'on est tjrs en création */}
                        <Route path="add_step2/:vehicleId"
                               element={<VehicleModal step="1" children={<VehicleStep2/>}></VehicleModal>}/>
                        <Route path="step2/:vehicleId"
                               element={<VehicleModal step="1" children={<VehicleStep2/>}></VehicleModal>}/>
                    </Route>
                    <Route path="test_stripe" element={<LoggedLayout children={<TestStripe/>}></LoggedLayout>}/>
                    <Route path="test_stripe_callback"
                           element={<LoggedLayout children={<TestStripe/>}></LoggedLayout>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    </I18nextProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals