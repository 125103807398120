import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    Box,
    Button, Checkbox,
    Container, FormControl, FormControlLabel,
    FormLabel,
    Grid, InputAdornment,
    MenuItem, Paper, Radio,
    Select,
    Tabs
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {useOutletContext, useParams} from "react-router-dom";
import '../App.css';
import Tab from "@mui/material/Tab";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import LocationServiceTab from "./LocationServiceTab";
import {withStyles} from "@mui/styles";
import CarService from "../services/CarService";

function LocationServices(props, ref) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');
    const formRef = React.useRef();
    const token = localStorage.getItem('accessToken');

    const defaultServiceType = 1;
    const [serviceType, setServiceType] = useState();
    const prevServiceType = usePrevious(serviceType);

    const defaultCurrency = 'CHF';
    const [currency, setCurrency] = useState();

    const [locationId, setLocationId] = useState(null);

    let params = useParams();


    // For tabs
    const [value, setValue] = React.useState(0);
    const tabChangedHandler = (event, newValue) => {
        // console.log("New value = " + newValue);
        setValue(newValue);
    };

    const [servicesData, setServicesData] = useState([]);

    let newDataCount = 0;

    const servicesDataRef = useRef(null);
    servicesDataRef.current = servicesData;

    const locationIdRef = useRef(null);
    locationIdRef.current = locationId;

    useEffect(() => {
        if (params.locationId != null) {
            getFromServer(params.locationId);
        }

        props.previous_btn.current.style.display = 'none';
        // props.previous_btn.current.addEventListener('click', () => console.log('back'));

        props.next_btn.current.addEventListener('click', nextClickedHandler);

        return () => {
            props.next_btn.current && props.next_btn.current.removeEventListener('click', nextClickedHandler);
        }
    }, [])


    useEffect(() => {
        servicesDataRef.current.map(serviceData => {
            serviceData.currency = currency;
        });
    }, [currency]);

    useEffect(() => {
        console.log("TYPE : " + prevServiceType + '=>' + serviceType);
        servicesDataRef.current.map(serviceData => {
            serviceData.serviceType = serviceType;
        });
        // NB Part du principe qu'on a accès aux servicesData au moment ou est setté serviceType
        if (serviceType == 1) {
            consolidateCategoryPrices(servicesDataRef.current);
        } else {
            if (prevServiceType == 1 && serviceType == 2) {
                // On clean la liste et on rajoute des defaults
                servicesDataRef.current.map(serviceData => {
                    serviceData.prices = [];
                    serviceData.options = [];
                    addCategoryPriceToModel(serviceData, t('location.services.new_category_default_name'), null, null);
                });
                refreshData();
            }
        }

    }, [serviceType])

    useImperativeHandle(ref, () => ({
        wantChangeTab(stepValue) {
            // TODO Save or warn...
            props.changeStepFromContentHandler(stepValue);
        },
    }));

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    function nextClickedHandler() {
        // console.log(servicesData);
        let data = {
            id: locationIdRef.current,
            services: servicesDataRef.current
        }

        // console.log(servicesDataRef.current);
        console.log("DATA to server :");
        console.log(data);

        // Check
        let errorNull = false;
        data.services.forEach(service => {
            service.prices.forEach(priceData => {
                if (priceData.price == null || priceData.price == undefined || isNaN(priceData.price)) {
                    errorNull = true;
                }
            });
        });

        if (errorNull) {
            Swal.fire({
                text: t('location.services.error_null_price'),
                icon: 'error',
                customClass: {
                    container: 'my-swal'
                }
            });
            return
        }

        sendToServer(data);
    }

    // TODO redondant
    async function getFromServer(id) {
        return fetch(apiUri + 'location_get/' + id, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(data => data.json())
            .then(data => {
                if (data.state === 'OK') {
                    setLocationId(data.location.locationId);

                    if (data.location.services.length == 0) {
                        data.location.services.push(createService(defaultServiceType, defaultCurrency));
                    }

                    setServicesData(data.location.services);
                    servicesDataRef.current = data.location.services;

                    setServiceType(data.location.services[0].serviceType);
                    setCurrency(data.location.services[0].currency);
                    props.contentLoadedHandler(data.location);
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            })
    }

    //
    async function sendToServer(data) {
        return fetch(apiUri + 'location_services', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body:
                JSON.stringify(data)
        })
            .then(data => data.json())
            .then(data => {
                if (data.state === 'OK') {
                    Swal.fire({
                        text: t('location.info.success'),
                        icon: 'success',
                        timer: 2000,
                        customClass: {
                            container: 'my-swal'
                        }
                    })
                        .then(() => {
                                if (!props.isEditMode) {
                                    props.changeStepFromContentHandler(3);
                                }
                            }
                        );
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            })
    }

    function consolidateCategoryPrices(servicesData) {
        // With cars
        if (serviceType == 1) {
            servicesData.map(serviceData => {
                let keptPrices = [];
                CarService.carCategories.map(carData => {
                    let priceData = getServicePriceByLabel(serviceData, t(carData.label));
                    if (priceData == null) {
                        priceData = addCategoryPriceToModel(serviceData, t(carData.label), carData.icon, null);
                    } else {
                        priceData.icon = carData.icon;
                    }
                    // console.log('FOUND : ');
                    // console.log(priceData);
                    keptPrices.push(priceData);
                });

                // console.log(keptPrices);

                serviceData.prices = keptPrices;

                // serviceData.prices.forEach(priceData => {
                //     if (keptPrices.indexOf(priceData) == -1) {
                //         serviceData.prices.splice(serviceData.prices.indexOf(priceData), 1);
                //     }
                // });
                // console.log(serviceData.prices);
            });
        }
        refreshData();
    }

    function getServicePriceByLabel(serviceData, label) {
        const price = serviceData.prices.find(element => element.categoryName == label);
        // console.log(label);
        // console.log(price);
        return price;
    }

    function getOptionPriceByLabel(optionData, label) {
        const price = optionData.prices.find(element => element.categoryName == label);
        return price;
    }

    function addServiceClickedHandler() {
        // Only for custom categories
        console.log(serviceType);
        if (serviceType != 1) {
            Swal.fire({
                title: t('location.services.copy_last_service_proposition'),
                showDenyButton: true,
                // showCancelButton: true,
                confirmButtonText: t('common.yes'),
                denyButtonText: t('common.no'),
                customClass: {
                    container: 'my-swal'
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    addService(servicesData.slice(-1)[0]); // Copy last element
                } else if (result.isDenied) {
                    addService();
                }
            })
        } else {
            addService();
        }

    }

    function addService(copyFromService = null) {
        let newServiceData = createService(serviceType, currency, copyFromService);
        servicesData.push(newServiceData);
        consolidateCategoryPrices(servicesData);
    }

    function createService(serviceType, currency, copyFromService = null) {
        let c = servicesData.length;
        newDataCount++;
        let newServiceData = {
            "id": "N" + newDataCount,
            "name": t('location.services.new_service_default_name') + " " + (c + 1),
            "description": "",
            "serviceType": serviceType,
            "carwashCategory": 1,
            "currency": currency,
            "prices": [],
            "options": []
        }

        if (copyFromService != null) {
            copyFromService.prices.forEach(servicePrice => {
                newDataCount++;
                newServiceData.prices.push({
                    categoryName: servicePrice.categoryName,
                    id: 'N' + newDataCount,
                    description: servicePrice.description,
                })
            });
        } else {
            addCategoryHandler(newServiceData);
        }

        return newServiceData;
    }

    function deleteServiceHandler(e, serviceData) {

        Swal.fire({
            title: t('location.services.delete_service_warning'),
            showDenyButton: true,
            // showCancelButton: true,
            confirmButtonText: t('common.yes'),
            denyButtonText: t('common.no'),
            customClass: {
                container: 'my-swal'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                servicesData.splice(servicesData.indexOf(serviceData), 1);
                // First tab
                setValue(0);
                refreshData();
            } else if (result.isDenied) {
                return;
            }
        })
    }

    function addCategoryHandler(serviceData) {
        let len = serviceData.prices.length;
        let price = 0;

        // On reprend le prix du dernier
        if (len > 0) {
            let lastCategory = serviceData.prices[len - 1];
            price = lastCategory.price;
        }

        let label = t('location.services.new_category_default_name') + (len > 0 ? (" " + (len + 1)) : "");

        addCategoryPriceToModel(serviceData, label, null, price);
        refreshData();
    }

    //
    function addCategoryPriceToModel(serviceData, label, icon, price) {
        // To prices
        newDataCount++;

        let priceData = {
            "id": 'N' + newDataCount,
            "categoryName": label,
            "icon": icon,
            "price": price
        }

        serviceData.prices.push(priceData);

        serviceData.options.map(option => {
            let optionPrice = 0;
            let len = option.prices.length;
            if (len > 0) {
                let lastCategory = option.prices[len - 1];
                optionPrice = lastCategory.price;
            }
            newDataCount++
            let newOptionPrice = {
                "id": 'N' + newDataCount,
                "categoryName": label,
                "price": optionPrice
            };
            option.prices.push(newOptionPrice);
        });
        return priceData;
    }

    function deleteCategoryHandler(serviceData, priceData) {
        // To prices
        serviceData.prices.splice(serviceData.prices.indexOf(priceData), 1);

        // To optionPrices
        serviceData.options.map(option => {
            priceData = getOptionPriceByLabel(option, priceData.categoryName);
            option.prices.splice(option.prices.indexOf(priceData), 1);
        });
        refreshData();
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function addServiceOptionHandler(e, serviceData) {
        let c = serviceData.options.length;
        newDataCount++;

        let newOption = {
            "id": "N" + newDataCount,
            "name": "Option " + (c + 1),
            "description": "Nouvelle option",
            "prices": []
        }

        // Prices
        serviceData.prices.map(priceData => {
            newDataCount++;
            newOption.prices.push({
                "id": "N" + newDataCount,
                "categoryName": priceData.categoryName,
                "price": 0
            });
        });

        serviceData.options.push(newOption);

        let newServiceData = servicesData.slice(0);
        setServicesData(newServiceData);
    }

    function deleteServiceOptionHandler(e, optionData, serviceOptionsData) {
        // console.log("Delete option !");
        // console.log(e, optionData);

        serviceOptionsData.splice(serviceOptionsData.indexOf(optionData), 1);
        refreshData();
    }

    function refreshData() {
        let newServiceData = servicesData.slice(0);
        setServicesData(newServiceData);
    }

    return (
        <Container maxWidth={false}>
            {servicesData.length > 0 &&
            <>
                <Grid container sx={{p: 2}}>
                    <Grid item sx={{ml: 2}}>
                        {t('location.services.service_type') + ' '}
                        <Select
                            id="serviceType"
                            // label={t('location.services.service_type')}
                            value={serviceType}
                            onChange={
                                e => {
                                    setServiceType(e.target.value);
                                }
                            }
                        >
                            <MenuItem value="1">{t('location.services.service_carwash')}</MenuItem>
                            <MenuItem value="2">{t('location.services.service_tires')}</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item sx={{ml: 2}}>
                        {t('location.services.currency') + ' '}
                        <Select
                            id="currency"
                            // label={t('location.services.currency')}
                            value={currency}
                            onChange={
                                e => {
                                    setCurrency(e.target.value);
                                }
                            }
                        >
                            <MenuItem value="CHF">{t('location.services.currency_chf')}</MenuItem>
                            <MenuItem value="EUR">{t('location.services.currency_eur')}</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                {/*<BasicTabs/>*/}
                <Box sx={{width: '100%'}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value} onChange={tabChangedHandler} aria-label="basic tabs example">
                            {servicesData.map((service, index) => {
                                return <Tab key={index} label={service.name} {...a11yProps(index)} />
                            })
                            }
                            <Tab icon={<AddCircleIcon/>} iconPosition="start" wrapped
                                 onClick={addServiceClickedHandler}/>
                        </Tabs>
                    </Box>
                    {servicesData.map((serviceData, index) => {
                        return <LocationServiceTab
                            key={index}
                            currentTabIndex={value}
                            index={index}
                            currency={currency}
                            serviceType={serviceType}
                            serviceData={serviceData}
                            canDelete={servicesData.length > 1}
                            deleteServiceHandler={deleteServiceHandler}
                            addCategoryHandler={addCategoryHandler}
                            deleteCategoryHandler={deleteCategoryHandler}
                            newOptionHandler={addServiceOptionHandler}
                            deleteOptionHandler={deleteServiceOptionHandler}
                            refreshDataHandler={refreshData}
                        />
                    })
                    }
                </Box>
            </>}
        </Container>
    )
};

export default forwardRef(LocationServices);