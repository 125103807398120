import React, {useEffect, useState} from 'react';

import {Box, Button, Typography} from "@mui/material";
import {Navigate, Redirect, useLocation, useOutletContext, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";

export default function ProfileStripe(props) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');

    const location = useLocation();
    const [searchParams] = useSearchParams();
    const token = localStorage.getItem('accessToken');
    const [stripeLinked, setStripeLinked] = useState(false);

    let stripeConnectApiToken;
    if (process.env.REACT_APP_STRIPE_TEST_MODE) {
        stripeConnectApiToken = process.env.REACT_APP_STRIPE_CONNECT_API_TOKEN_TEST;
    } else {
        stripeConnectApiToken = process.env.REACT_APP_STRIPE_CONNECT_API_TOKEN;
    }

    let stripeUrl = 'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=' + stripeConnectApiToken + '&scope=read_write&redirect_uri=' + process.env.REACT_APP_STRIPE_REDIRECT_URI;

    useEffect(() => {
        props.setLabel('profile_stripe.title');

        if (location.pathname == "/stripe_callback") {
            // error=access_denied&error_description=The+user+denied+your+request
            // scope=read_write&code=ac_M80ZiedDaUWxvdcS9x3zlCJF15hGc49e
            let error = searchParams.get("error");
            if (error != null) {
                Swal.fire(t('login.fail.title'), t('profile_stripe.link_account_failed'), 'error');
            } else {
                postAuth(searchParams.get('code'));
            }
        }
    }, [])

    async function postAuth(code) {
        let data = new FormData();
        data.append('code', code);
        return fetch(apiUri + 'stripe_link_account', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/json'
            },
            body: data
        })
            .then(data => data.json())
            .then(data => {
                if (data.state == 'OK') {
                    Swal.fire('', t('profile_stripe.link_account_success'), 'success')
                        .then((value) => {
                            setStripeLinked(true);
                        });
                } else {
                    Swal.fire(t('login.fail.title'), t('profile_stripe.link_account_failed'), 'error');
                }
            })
    }

    return (
        <>
            {
                stripeLinked ?
                    <Navigate to="/dashboard"/>
                    : <>
                        <Typography variant="h4">
                            {t("profile_stripe.title")}
                        </Typography>
                        {/*<Button variant="outlined">Edit</Button>*/}
                        <Box width="100%" sx={{mt: 1}}/>
                        <Button
                            href={stripeUrl}
                            variant="contained"
                            color="yellow"
                            sx={{mt: '15px', mb: '20px'}}
                        >
                            {t('profile_stripe.button_add_account')}
                        </Button>
                    </>
            }
        </>
    );
}