import {Button, Link} from "@mui/material";

import {Navigate, useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Swal from 'sweetalert2'
import React, {useState} from "react";

function DeleteAccount(props) {
    const token = localStorage.getItem('accessToken');
    const [apiUri] = useOutletContext();
    const [deleteDone, setDeleteDone] = useState(false);
    const {t} = useTranslation('common');

    const deleteBtnHandler = event => {
        Swal.fire({
            title: t('delete_account.confirmation_label'),
            showCancelButton: true,
            confirmButtonColor: '#dd6b55',
            confirmButtonText: t('delete_account.confirmation_yes'),
            cancelButtonText: t('delete_account.confirmation_no'),
        }).then((result) => {
            if (result.isConfirmed) {
                deleteAccount();
            }
        })
    }

    async function deleteAccount() {
        return fetch(apiUri + 'account', {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(data => data.json())
            .then(data => {
                if (data.state == 'OK') {
                    Swal.fire(t('delete_account.success'), '', 'success', {timer: 2000})
                        .then(() => {
                            localStorage.removeItem('accessToken');
                            setDeleteDone(true);
                        })
                } else {
                    Swal.fire(t('login.fail.title'), t(data.message), "error");
                }
            })
    }

    return <>{deleteDone ?
        <Navigate to='/login'/>
        :
        <Link
            href="#"
            color="#8b0000"
            onClick={deleteBtnHandler}
        >
            {t("profile.delete_account")}
        </Link>
    }</>

}

export default DeleteAccount;