import React, {useEffect, useState} from 'react';
import {
    Box, Button, Grid, Typography
} from "@mui/material";
import {Link, Navigate, useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Swal from 'sweetalert2'

export default function Dashboard(props) {
    const token = localStorage.getItem('accessToken');
    const {t} = useTranslation('common');
    const [apiUri] = useOutletContext();
    const [loaded, setLoaded] = useState(false);
    const [actionsToDo, setActionsToDo] = useState([]);

    useEffect(() => {
        props.setLabel('dashboard.title');
        accountGet();
    }, [])

    if (token == null) {
        return ( //
            <Navigate to='/login'/>
        );
    }

    async function accountGet() {
        return fetch(apiUri + 'account', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(data => data.json())
            .then(data => {
                if (data.state == 'OK') {
                    setLoaded(true);
                    if ('actions_to_do' in data) {
                        setActionsToDo(data.actions_to_do);
                    }
                } else {
                    Swal.fire(t('login.fail.title'), t(data.message), "error");
                }
            })
    }

    return (
        <>
            {/*<Avatar src={user.avatar} className={classes.large} />*/}
            <Typography variant="h4">
                {t("dashboard.title")}
            </Typography>
            <Box width="100%" sx={{mt: 1}}/>
            <Typography variant="h5">
                {t("dashboard.welcome")}
            </Typography>

            {/*<Button variant="outlined">Edit</Button>*/}
            <Box width="100%" sx={{mt: 1}}/>
            {loaded &&
            <>
                <Box width="100%" sx={{mt: 1}}/>
                {
                    actionsToDo.includes('add_stripe_account') &&
                    <Grid justifyContent="center" container>
                        {t('dashboard.actions_to_do.add_stripe_account.description')}
                        <Box width="100%" sx={{mt: 1}}/>
                        <Link to="/profile_stripe" style={{textDecoration: 'none'}}>
                            <Button
                                variant="contained"
                                color="yellow"
                                sx={{mt: '15px', mb: '20px'}}
                            >
                                {t('dashboard.actions_to_do.add_stripe_account.button_label')}
                            </Button>
                        </Link>
                    </Grid>
                }
                {
                    actionsToDo.includes('add_first_location') &&
                    <Grid justifyContent="center" container>
                        {t('dashboard.actions_to_do.add_first_location.description')}
                        <Box width="100%" sx={{mt: 1}}/>
                        <Link to="/location/add" style={{textDecoration: 'none'}}>
                            <Button
                                variant="contained"
                                color="yellow"
                                sx={{mt: '15px', mb: '20px'}}
                            >
                                {t('dashboard.actions_to_do.add_first_location.button_label')}
                            </Button>
                        </Link>
                    </Grid>
                }
            </>
            }
        </>
    );
}