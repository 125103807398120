import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    Autocomplete,
    Box,
    Button, Checkbox,
    Container, FormControlLabel,
    Grid,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {useOutletContext, useParams} from "react-router-dom";
import '../App.css';
import ReactCountryFlag from "react-country-flag"
import CarService from "../services/CarService";
import VehicleSelector from "./VehicleSelector";

function VehicleStep1(props, ref) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');
    const formRef = React.useRef();
    const token = localStorage.getItem('accessToken');

    const [vehicleId, setVehicleId] = useState(null);

    const [vehicleData, setVehicleData] = useState(null);

    const [vinError, setVinError] = useState(null);
    const [country, setCountry] = useState('ch');

    const [brand, setBrand] = useState(null);   // Object
    const [model, setModel] = useState(null);   // Object
    const [carCategory, setCarCategory] = useState(null);   // Object

    const [showBrand, setShowBrand] = useState(false);
    const [showModel, setShowModel] = useState(false);
    const [showCarCategory, setShowCarCategory] = useState(false);

    // Does vin / brand / model locked
    const [locked, setLocked] = useState(false);
    // Does carCategory locked

    const [brandList, setBrandList] = useState(null);
    const [modelList, setModelList] = useState(null);

    const [showCustomBrand, setShowCustomBrand] = useState(false);
    const [showCustomModel, setShowCustomModel] = useState(false);
    const [carCategoryLocked, setCarCategoryLocked] = useState(false);

    // TODO Ajouter editmode, en editmode, on ne peut pas changer la carCategorie par exemple ( pas le bouton dispo )

    let params = useParams();

    useEffect(() => {
        if (params.vehicleId != null) {
            getFromServer(params.vehicleId);
        } else {
            props.contentLoadedHandler(null);   // Trigger stepper vue
        }

        // TODO Pourrait être appelé qu'au besoin ( mais ici ca évite les chargements en cours de route )
        getBrands();

        props.previous_btn.current.style.display = 'none';
        // props.previous_btn.current.addEventListener('click', () => console.log('back'));
        props.next_btn.current.addEventListener('click', submitData);

        return () => {
            props.next_btn.current && props.next_btn.current.removeEventListener('click', submitData);
        }
    }, [])

    function isEditMode()
    {
        return params.vehicleId != null;
    }

    function submitData() {
        if (!formRef.current.reportValidity()) {
            return;
        }
        formRef.current.dispatchEvent(
            new Event("submit", {cancelable: true, bubbles: true})
        );
    }

    function brandChangedHandler(e, value) {
        // console.log(e.target.value);
        // console.log(value);
        setBrand(value);
        setModel(null);

        setShowCarCategory(false);
        if (value == null) {
            setShowModel(false);
            return;
        }

        setShowModel(true);
        getModels(value.id);
    }

    function modelChangedHandler(e, value) {
        setModel(value);
        if (value == null) {
            setShowCarCategory(false);
            return;
        }

        setShowCarCategory(true);
        let carCategory = CarService.getCarCategoryById(value.car_category_id);
        setCarCategory(carCategory);
        setCarCategoryLocked(carCategory != null);
    }

    function vehicleNotFoundClickedHandler(e) {
        if (brand == null) {
            setShowBrand(false)
            setShowCustomBrand(true);
        }
        setShowModel(false);
        setShowCustomModel(true);
        setCarCategoryLocked(false);
        setShowCarCategory(true);
    }

    useImperativeHandle(ref, () => ({
        wantChangeTab(stepValue) {
            // TODO Save or warn..
            props.changeStepFromContentHandler(stepValue);
        },
    }));

    // TODO redondant
    async function getFromServer(id) {
        return fetch(apiUri + 'vehicle_get/' + id, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(data => data.json())
            .then(data => {
                if (data.state === 'OK') {
                    setVehicleId(data.vehicle.vehicleId);
                    setVehicleData(data.vehicle);

                    formRef.current.elements['vin'].value = data.vehicle.reference.vin;

                    if (data.vehicle.reference.country != null) {
                        setCountry(data.vehicle.reference.country);
                    }

                    setShowBrand(false);
                    setShowModel(false)

                    setShowCustomBrand(true);
                    setShowCustomModel(true);

                    setCarCategory(CarService.getCarCategoryById(data.vehicle.reference.car_category_id));
                    setCarCategoryLocked(true);
                    setShowCarCategory(true);

                    setLocked(true);

                    props.contentLoadedHandler(data.vehicle);
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            })
    }

    //
    async function sendToServer(data) {
        return fetch(apiUri + 'vehicle_register_step1', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body:
                JSON.stringify(data)
        })
            .then(data => data.json())
            .then(data => {
                    if (data.state === 'OK') {

                        let newVehicleId = data.vehicleId;

                        Swal.fire({
                            text: t('vehicle.form.success'),
                            icon: 'success',
                            timer: 2000,
                            customClass: {
                                container: 'my-swal'
                            }
                        })
                            .then(() => {
                                    if (!props.isEditMode) {
                                        props.changeStepFromContentHandler(1, newVehicleId);
                                    }
                                }
                            );
                    } else {
                        Swal.fire({
                            text: t(data.message),
                            icon: 'error',
                            customClass: {
                                container: 'my-swal'
                            }
                        });
                    }
                }
            )
    }

    function getBrands() {
        return fetch(apiUri + 'vehicle_brand_index', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(data => data.json())
            .then(data => {
                if (data.state === 'OK') {
                    setBrandList(data.brands);
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            })
    }

    function getModels(brandId) {
        return fetch(apiUri + 'vehicle_model/' + brandId, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(data => data.json())
            .then(data => {
                if (data.state === 'OK') {
                    setModelList(data.models);
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            })
    }

    function submitHandler(event) {
        if (!formRef.current.reportValidity()) {
            return;
        }

        if (carCategory == null) {
            Swal.fire({
                text: t('vehicle.form.category_mandatory_error'),
                icon: 'warning',
                customClass: {
                    container: 'my-swal'
                }
            });
            return;
        }

        let data = {
            id: vehicleId,
            country: country,
            vin: formRef.current.elements['vin'].value,
            brand_id: brand != null ? brand.id : null,
            model_id: model != null ? model.id : null,
            custom_brand: formRef.current.elements['custom_brand'] != null ? formRef.current.elements['custom_brand'].value : null,
            custom_model: formRef.current.elements['custom_model'] != null ? formRef.current.elements['custom_model'].value : null,
            car_category_id: carCategory.id,
            car_category_locked: carCategoryLocked ? '1' : '0'
        }
        sendToServer(data);
    }

    function submitVinHandler(event) {
        // event.preventDefault();

        console.log("VIN", formRef.current.elements['vin'].value);

        if (formRef.current.elements['vin'].value.length < 17) {
            setVinError('vehicle.form.vin_error_incorrect');
            return;
        }

        setVinError(null);

        let data = {
            vin: formRef.current.elements['vin'].value
        }

        if (vehicleId != null) {
            data.id = vehicleId;
        }

        // Submit
        fetch(apiUri + 'vin_check', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body:
                JSON.stringify(data)
        })
            .then(data => data.json())
            .then(data => {
                if (data.state === 'OK') {
                    setVehicleId(data.vehicle.vehicleId);
                    setVehicleData(data.vehicle);

                    formRef.current.elements['vin'].value = data.vehicle.reference.vin;

                    setShowBrand(false);
                    setShowModel(false);
                    setShowCustomBrand(true);
                    setShowCustomModel(true);
                    setShowCarCategory(true);
                    setLocked(true);

                    let carCategory = CarService.getCarCategoryById(data.vehicle.reference.car_category_id);

                    setCarCategory(carCategory);
                    setCarCategoryLocked(carCategory != null);
                } else {
                    setVinError('vehicle.form.vin_error');
                    setShowBrand(true);
                    setShowCustomBrand(false);
                    setShowCustomModel(false);
                }
            })
    }

    function wrongCarCategoryClickedHandler() {
        setCarCategoryLocked(false);
    }

    return (
        <Container>
            <>
                <form className="form-inline" onSubmit={submitHandler} ref={formRef}>
                    <Grid container justifyContent="center" sx={{marginTop: '10px'}}>
                        <Container>
                            <Grid container justifyContent="center">
                                <Select
                                    id="country"
                                    value={country}
                                    onChange={
                                        e => {
                                            setCountry(e.target.value);
                                        }
                                    }
                                >
                                    <MenuItem value="fr"><ReactCountryFlag countryCode="FR" svg style={{
                                        width: '2em',
                                        height: '2em',
                                        marginRight: '5px'
                                    }}/> {t('countries.france')}</MenuItem>
                                    <MenuItem value="ch"><ReactCountryFlag countryCode="CH" svg style={{
                                        width: '2em',
                                        height: '2em',
                                        marginRight: '5px'
                                    }}/> {t('countries.switzerland')}</MenuItem>
                                </Select>
                            </Grid>
                            {/* VIN  */}
                            <Box sx={{width: '100%', backgroundColor: '#515151', borderRadius: '16px'}}>
                                <Grid container justifyContent="center" alignContent="center"
                                      sx={{marginTop: '10px'}}>
                                    <Grid item xs={2} container alignItems="center" justifyContent="center">
                                        <Typography>{t('vehicle.form.vin_label')}</Typography>
                                    </Grid>
                                    <Grid item xs={8} container alignItems="center" justifyContent="center">
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="vin"
                                            disabled={locked}
                                            // label={t('location.info.contact_firstname')}
                                            // defaultValue={vehicleData.contact ? vehicleData.contact.firstName : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={2} container alignItems="center" justifyContent="center">
                                        <Button
                                            disabled={locked}
                                            variant="contained"
                                            color="yellow"
                                            onClick={submitVinHandler}
                                        >
                                            {t('vehicle.form.vin_submit')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box>
                                <Grid container justifyContent="center" alignContent="center"
                                      sx={{marginTop: '10px'}}>
                                    {vinError &&
                                    <Typography color="red"
                                                visibility="none">{t(vinError)}</Typography>
                                    }
                                </Grid>
                            </Box>

                            {showBrand && !locked && brandList != null &&
                            <>
                                <Autocomplete
                                    sx={{marginTop: '10px'}}
                                    id="brand"
                                    disabled={locked}
                                    value={brand}
                                    options={brandList}
                                    renderInput={(params) =>
                                        <TextField {...params} label={t('vehicle.form.brand')}
                                                   required
                                        />
                                    }
                                    onChange={brandChangedHandler}
                                />
                            </>
                            }

                            {showModel && !locked && modelList != null &&
                            <Autocomplete
                                sx={{marginTop: '10px'}}
                                id="model"
                                required
                                disabled={locked}
                                value={model}
                                options={modelList}
                                renderInput={(params) =>
                                    <TextField {...params} label={t('vehicle.form.model')}
                                               required
                                    />
                                }
                                onChange={modelChangedHandler}
                            />
                            }

                            {(showModel || showBrand) && (brand == null || model == null) && !showCustomModel &&
                            <a href="#"
                               onClick={vehicleNotFoundClickedHandler}>{t('vehicle.form.brand_not_found')}</a>
                            }

                            {showCustomBrand &&
                            <TextField
                                required
                                disabled={locked}
                                margin="normal"
                                fullWidth
                                id="custom_brand"
                                label={t('vehicle.form.brand')}
                                defaultValue={locked ? vehicleData.reference.brand : null}
                            />
                            }
                            {showCustomModel &&
                            <TextField
                                required
                                disabled={locked}
                                margin="normal"
                                fullWidth
                                id="custom_model"
                                label={t('vehicle.form.model')}
                                defaultValue={locked ? vehicleData.reference.model : null}
                            />
                            }
                            {showCarCategory &&
                            <>
                                <br/>
                                {t('vehicle.form.category_reminder')} {carCategory && t(carCategory.label)}
                                <Grid container
                                      sx={{marginTop: '10px'}}
                                >
                                    {
                                        CarService.carCategories.map((car, i) => {
                                            return (<VehicleSelector
                                                key={i}
                                                carData={car}
                                                selected={
                                                    car == carCategory
                                                }
                                                onClickHandler={
                                                    (e) => {
                                                        if (carCategoryLocked) {
                                                            return;
                                                        }
                                                        setCarCategory(car);
                                                    }
                                                }
                                            />);
                                        })
                                    }
                                </Grid>
                                {
                                    !isEditMode() && carCategoryLocked &&
                                    <Grid container justifyContent="center">
                                        <Button
                                            // variant="contained"
                                            // color="yellow"
                                            onClick={wrongCarCategoryClickedHandler}
                                        >
                                            {t('vehicle.form.wrong_car_category')}
                                        </Button>
                                    </Grid>
                                }
                            </>
                            }
                        </Container>
                    </Grid>
                </form>
            </>
        </Container>
    )
};

export default forwardRef(VehicleStep1);