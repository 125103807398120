import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {useOutletContext} from "react-router-dom";
import Swal from 'sweetalert2'
import {useTranslation} from "react-i18next";

import btnEdit from "../assets/buttons/edit/write-white-yellow.svg";


export default function PictureUpload(props) {
    const classes = useStyles();
    const token = localStorage.getItem('accessToken');
    const [imageUrl, setImageUrl] = useState(null);

    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');

    const {
        height,
        width
    } = props;

    useEffect(() => {
        setImageUrl(props.picture_uri);
    }, [])

    const handleUploadClick = event => {
        var file = event.target.files[0];

        let data = new FormData();
        data.append('id', props.model_id);
        data.append("file", file);
        return fetch(apiUri + props.api_uri, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
            },
            body: data
        })
            .then(data => data.json())
            .then(data => {
                if (data.state == 'OK') {
                    setImageUrl(data.picture)
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            });
    };

    return <>
        <input
            accept="image/*"
            className={classes.uploadInput}
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleUploadClick}
        />
        <label htmlFor="contained-button-file">
            <img src={imageUrl == null ? props.default_picture : imageUrl} style={{
                height : height,
                width : width
            }} className={classes.profilePictureImg}/>
            <img src={btnEdit} className={classes.editBtn}/>
        </label>
    </>
}

const useStyles = makeStyles((theme) => ({
    profilePictureImg: {
        // height: '100px',
        // width: '100px',
        objectFit: 'contain',
        borderRadius: '5px',
        cursor: 'pointer'
    },
    editBtn: {
        height: '32px',
        cursor: 'pointer',
        marginLeft: '5px'
    },
    uploadInput: {
        display: "none"
    }
}));

PictureUpload.defaultProps = {
    width: '100px',
    height: '100px'
}