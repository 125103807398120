import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {TextField} from "@mui/material";

export default function LocationOptionPrice(props) {
    const {t} = useTranslation('common');
    const [price, setPrice] = useState(props.priceData.price);

    useEffect(() => {
        if (props.uniquePrice) {
            setPrice(props.uniquePriceValue);
        }
    }, [props.uniquePrice, props.uniquePriceValue]);

    useEffect(() => {
        // console.log(props.priceData);
        if (props.priceData) {
            setPrice(props.priceData.price);
        }
    }, [props.priceData]);

    useEffect(() => {
        // Update model
        props.priceData.price = price;
    }, [price]);

    return (<TextField
        type="number"
        margin="normal"
        size="small"
        fullWidth
        id="label"
        onChange={(e) => {
            let newPrice = parseFloat(e.target.value);
            if (newPrice < 0) {
                newPrice = 0;
            }
            setPrice(newPrice);
            props.priceChangedHandler(e);
        }}
        value={price}
    />)
}