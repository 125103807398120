import React, {useState} from 'react';
import Swal from 'sweetalert2'

import logo from '../assets/logo-header.png';
import {Button, Card, Container, Grid, Switch, TextField, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Navigate, useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";


export default function Register(props) {
    const classes = useStyles();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [apiUri] = useOutletContext();
    const [accountType, setAccountType] = useState(1);
    const formRef = React.useRef();
    const {t} = useTranslation('common');
    const [registerDone, setRegisterDone] = useState(false);

    async function sendRegister(data) {
        return fetch(apiUri + 'register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body:
                JSON.stringify(data)
        })
            .then(data => data.json())
    }

    const handleSubmit = async e => {
        e.preventDefault();


        if (!formRef.current.reportValidity()) {
            return;
        }

        if (password != confirmPassword) {
            Swal.fire(t('register.error_title'), t('register.error_password'), "error");
            return;
        }

        const response = await sendRegister({
            'user': {
                accountType,
                lastName,
                firstName,
                email,
                password,
                phone,
                company
            }
        });
        if ('state' in response && response['state'] == 'OK') {
            Swal.fire(t('register.success.title'), t('register.success.desc'), "success", {
                buttons: false,
                timer: 2000,
            })
                .then((value) => {
                    setRegisterDone(true);
                });
        } else {
            Swal.fire(t('common.error_title'), t(response.message), "error");
        }
    }

    return (
        <>
            {registerDone ?
                <Navigate to='/login'/>
                :
                <Grid container
                      alignItems="center"
                      justifyContent="center">
                    {/* Login Form  */}
                    <Container maxWidth='sm' className={classes.loginContainer} sx={{borderRadius: '16px'}}
                               disableGutters>
                        <Container>
                            <img src={logo} className={classes.logo}/>
                        </Container>
                        <Container>
                            <Typography align='center' variant='subtitle1'>
                                {t('register.title')}
                            </Typography>
                            <form className={classes.form} noValidate onSubmit={handleSubmit} ref={formRef}>
                                <Grid container alignItems="center"
                                      justifyContent="center">
                                    <Typography
                                        className={accountType == 1 ? classes.yellow : null}>{t('register.customer_label')}</Typography>
                                    <Switch onChange={function (e) {
                                        setAccountType(e.target.checked ? 2 : 1)
                                    }}/>
                                    <Typography
                                        className={accountType == 2 ? classes.yellow : null}>{t('register.company_label')}</Typography>
                                </Grid>
                                {accountType == 1 ?
                                    <>
                                        {/*<input type="text" value={this.state.value} onChange={this.handleChange} />*/}
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="firstname"
                                            name="firstname"
                                            label={t('register.firstname')}
                                            value={firstName}
                                            onChange={e => setFirstName(e.target.value)}
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="lastname"
                                            name="lastname"
                                            label={t('register.lastname')}
                                            value={lastName}
                                            onChange={e => setLastName(e.target.value)}
                                        />
                                    </>
                                    : <>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="company"
                                            name="company"
                                            label={t('register.company')}
                                            value={company}
                                            onChange={e => setCompany(e.target.value)}
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="phone"
                                            name="phone"
                                            label={t('register.phone')}
                                            value={phone}
                                            onChange={e => setPhone(e.target.value)}
                                        />
                                    </>
                                }
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label={t('register.email')}
                                    type="email"
                                    onChange={e => setEmail(e.target.value)}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password"
                                    name="password"
                                    label={t('register.password')}
                                    type="password"
                                    onChange={e => setPassword(e.target.value)}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="confirm_password"
                                    name="confirm_password"
                                    label={t('register.password_again')}
                                    type="password"
                                    onChange={e => setConfirmPassword(e.target.value)}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="yellow"
                                    className={classes.loginBtn}
                                    sx={{mt: '15px', mb: '20px'}}
                                >
                                    {t('register.btn_register')}
                                </Button>
                            </form>
                        </Container>
                    </Container>
                </Grid>}
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        // backgroundColor: '#49545d',  // Gris clair 1
        background: 'linear-gradient(#49545D, #1C2326)'
    },
    // input: {
    //     color: '#49545d'  // gris clair 2
    // },
    loginContainer: {
        backgroundColor: '#292e32'
    },
    registerContainer: {
        backgroundColor: '#1d2124', // Gris foncé 1
        borderTop: 'solid #373B3E',
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    logo: {
        width: '90%',
        margin: '20px'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    loginBtn: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#fbc70f',
        color: 'black',
    },
    white: {
        color: 'white'
    },
    yellow: {
        color: '#fbc70f'
    },
    centerText: {
        alignItems: "center",
        display: "flex"
    }
}));