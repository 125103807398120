import React, {useEffect, useState} from 'react';
import logo from '../assets/logo-header.png';
import {Box, Button, Container, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Link, useOutletContext, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function EmailConfirmation(props) {
    const classes = useStyles();

    const [apiUri] = useOutletContext();

    const {t} = useTranslation('common');

    const [searchParams] = useSearchParams();
    const [firstRender, setFirstRender] = useState(false);

    // sending / success / failed
    const [confirmationState, setConfirmationState] = useState('sending');

    useEffect(() => {
        if (!firstRender) {
            let email = searchParams.get("email");
            let key = searchParams.get("key");
            sendConfirmation({email, key});
        }
        setFirstRender(true);
    }, [firstRender])

    async function sendConfirmation(data) {
        return fetch(apiUri + 'confirm_email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body:
                JSON.stringify(data)
        })
            .then(data => data.json())
            .then(data => {
                if (data.state == 'OK') {
                    setConfirmationState('success');
                } else {
                    setConfirmationState('failed');
                }
            });
    }

    return (
        <Grid container
              alignItems="center"
              justifyContent="center">
            <Container maxWidth='sm' className={classes.loginContainer} sx={{borderRadius: '16px'}} disableGutters>
                <Container>
                    <img src={logo} className={classes.logo}/>
                </Container>
                <Container>
                    <Typography align='center' variant='subtitle1'>
                        {t('email_confirmation.' + confirmationState)}

                        {confirmationState == 'success' &&
                        <>
                            <Link to="/login" style={{ textDecoration: 'none' }}>
                                <Button
                                    margin="normal"
                                    fullWidth
                                    color="yellow"
                                    variant="contained"
                                    sx={{mt: '15px', mb: '20px'}}
                                >
                                    {t('login.btn_login')}
                                </Button>
                            </Link>
                        </>
                        }
                    </Typography>
                    <Box width="100%" sx={{mt: 1}}/>
                </Container>
            </Container>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        // backgroundColor: '#49545d',  // Gris clair 1
        background: 'linear-gradient(#49545D, #1C2326)'
    },
    loginContainer: {
        backgroundColor: '#292e32'
    },
    registerContainer: {
        backgroundColor: '#1d2124', // Gris foncé 1
        borderTop: 'solid #373B3E',
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    logo: {
        width: '90%',
        margin: '20px'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    loginBtn: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#fbc70f',
        color: 'black',
    },
    white: {
        color: 'white'
    },
    yellow: {
        color: '#fbc70f'
    },
    centerText: {
        alignItems: "center",
        display: "flex"
    }
}));