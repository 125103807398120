import React, {useEffect, useState} from "react";
import {Button, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";
import {useOutletContext} from "react-router-dom";

function VehicleDocumentRow({vehicleData, document, reloadHandler}) {
    const token = localStorage.getItem('accessToken');
    const {t} = useTranslation('common');
    const [apiUri] = useOutletContext();

    function deleteDocumentHandler(e) {
        e.stopPropagation();
        Swal.fire({
            title: t('vehicle.item.documents.delete.warning'),
            showCancelButton: true,
            confirmButtonColor: '#dd6b55',
            confirmButtonText: t('common.yes'),
            cancelButtonText: t('common.no'),
        }).then((result) => {
            if (result.isConfirmed) {
                deleteDocument();
            }
        })
    }

    async function deleteDocument() {
        return fetch(apiUri + 'vehicle_document', {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body:
                JSON.stringify({
                    vehicle_id : vehicleData.vehicleId,
                    id: document.id
                })
        })
            .then(data => data.json())
            .then(data => {
                if (data.state == 'OK') {
                    Swal.fire(t('vehicle.item.documents.delete.success'), '', 'success', {timer: 2000})
                        .then(() => {
                            reloadHandler();
                        })
                } else {
                    Swal.fire(t('login.fail.title'), t(data.message), "error");
                }
            })
    }

    return <Typography>
        <a href={document.picture} target="_blank">{document.label}</a>
        <Button onClick={(e) => deleteDocumentHandler(e)}>
            <DeleteIcon sx={
                {
                    color: 'orange',
                    fontSize: 16,
                }}/>
        </Button>
    </Typography>
}

export default VehicleDocumentRow;