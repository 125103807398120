import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Link,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import LocationCarItem from "./LocationCarItem";
import {useTranslation} from "react-i18next";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LocationCustomItem from "./LocationCustomItem";
import LocationServiceOptions from "./LocationServiceOptions";

export default function LocationServiceTab(props) {
    const maxCategoryCount = 12;
    const defaultUniquePrice = false;

    const {t} = useTranslation('common');

    const [uniquePrice, setUniquePrice] = useState(defaultUniquePrice);
    const [uniquePriceValue, setUniquePriceValue] = useState(0);

    const {
        index,
        currency,
        currentTabIndex,
        serviceType,
        canDelete,
        deleteServiceHandler,
        addCategoryHandler,
        deleteCategoryHandler,
        labelChangedHandler,
        serviceData,
        newOptionHandler,
        deleteOptionHandler,
        refreshDataHandler,
        ...other
    } = props;

    const [canAddCategory, setCanAddCategory] = useState(false);

    useEffect(() => {
        if (props.serviceType == 1) {
            setCanAddCategory(false);
        } else {
            setCanAddCategory(true);
        }
    }, [props.serviceType])

    function deleteServiceLocalHandler(e) {
        props.deleteServiceHandler(e, props.serviceData);
    }

    function priceChangedHandler(e, priceData) {
        setUniquePriceValue(e.target.value);
    }

    function newOptionLocalHandler(e) {
        newOptionHandler(e, props.serviceData);
    }

    return (
        <div
            role="tabpanel"
            hidden={currentTabIndex !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {currentTabIndex === index && (
                <Box sx={{p: 3}}>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="label"
                        label={t('location.services.service_label')}
                        value={props.serviceData.name}
                        onChange={(e) => {
                            props.serviceData.name = e.target.value;
                            props.refreshDataHandler();
                        }}
                        // defaultValue={props.serviceData.name}
                    />
                    <TextField
                        margin="normal"
                        multiline
                        rows={2}
                        fullWidth
                        id="label"
                        label={t('location.services.service_description')}
                        value={props.serviceData.description}
                        onChange={(e) => {
                            props.serviceData.description = e.target.value;
                            props.refreshDataHandler();
                        }}
                    />
                    {serviceType == 1 &&
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={props.serviceData.carwashCategory}
                            onChange={(e) => {
                                props.serviceData.carwashCategory = e.target.value;
                                props.refreshDataHandler();
                            }}
                        >
                            <FormControlLabel value="1" control={<Radio/>}
                                              label={t('location.services.carwash_interior')}/>
                            <FormControlLabel value="2" control={<Radio/>}
                                              label={t('location.services.carwash_exterior')}/>
                            <FormControlLabel value="3" control={<Radio/>}
                                              label={t('location.services.carwash_both')}/>
                        </RadioGroup>
                    </FormControl>
                    }
                    <br/>

                    <FormControlLabel
                        control={<Checkbox checked={uniquePrice} onChange={e => setUniquePrice(e.target.checked)}/>}
                        label={t('location.services.unique_price')}/>

                    <Grid container>
                        {props.serviceData.prices.map((priceData, index) => {
                            return <Grid item xs={12 / 5} key={index}>
                                {props.serviceType == 1 ?
                                    <LocationCarItem
                                        key={index}
                                        uniquePriceValue={uniquePriceValue}
                                        uniquePrice={uniquePrice}
                                        currency={currency}
                                        priceChangedHandler={(e) => priceChangedHandler(e, priceData)}
                                        priceData={priceData}
                                    /> :
                                    <LocationCustomItem
                                        key={index}
                                        canDelete={props.serviceData.prices.length > 1}
                                        uniquePriceValue={uniquePriceValue}
                                        uniquePrice={uniquePrice}
                                        currency={currency}
                                        priceChangedHandler={(e) => priceChangedHandler(e, priceData)}
                                        deleteCategoryHandler={
                                            () => {
                                                props.deleteCategoryHandler(serviceData, priceData);
                                            }

                                        }
                                        refreshDataHandler={props.refreshDataHandler}
                                        priceData={priceData}
                                    />
                                }
                            </Grid>
                        })
                        }
                        {
                            canAddCategory && (props.serviceData.prices.length < maxCategoryCount) &&
                            <Grid item xs={2}>
                                <Button
                                    style={{width: '100%', height: '100%'}}
                                    onClick={() => {
                                        props.addCategoryHandler(serviceData)
                                    }}
                                >
                                    <AddCircleIcon style={{width: '50px', height: '50px'}}/>
                                </Button>
                            </Grid>
                        }
                    </Grid>
                    <br/>
                    <LocationServiceOptions
                        // carCategoryData={carCategoryData}
                        serviceData={serviceData}
                        serviceOptionData={serviceData.options}
                        newOptionHandler={newOptionLocalHandler}
                        deleteOptionHandler={deleteOptionHandler}
                    />
                    {canDelete &&
                    <Link
                        href="#"
                        color="#8b0000"
                        onClick={deleteServiceLocalHandler}
                    >
                        {t('location.services.button_delete')}
                    </Link>
                    }
                </Box>
            )}
        </div>
    );
}