import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid, Typography
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from "../assets/buttons/info/info-white-black.png";

export default function VehicleItemSection({icon, label, detailContent}) {
    return (
        <Accordion sx={{
            backgroundColor: '#666666'
        }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                    height: '80px'
                }}
            >
                <Grid container>
                    <Grid item xs={0}>
                        <img src={icon} style={{width: '50px'}}></img>
                    </Grid>
                    <Grid item xs={10} container alignContent="center">
                        <Typography fontSize="18px" color="black"
                                    marginLeft="10px"><b>{label}</b></Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container>
                    {detailContent}
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}