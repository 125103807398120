import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, Grid, Typography} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import ImageButton from "./ImageButton";
import btnEdit from "../assets/buttons/edit/write-white-yellow.png";
import btnEditOver from "../assets/buttons/edit/write-white-yellow.png";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import VehicleItemSectionInfo from "./VehicleItemSectionInfo";
import VehicleItemSectionServiceHistory from "./VehicleItemSectionServiceHistory";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import VehicleItemSectionDocuments from "./VehicleItemSectionDocuments";

function ExpandMoreIcon() {
    return null;
}

export default function VehicleItem({vehicleData, deleteVehicleHandler, reloadHandler, vehicleDocumentTypes}) {
    const {t} = useTranslation('common');
    const classes = useStyles();

    const [selected, setSelected] = useState(true);

    function getEditPath(vehicleId) {
        let path = '/vehicle/step2/' + vehicleId;
        return path;
    }

    function itemClickedHandler(e) {
        setSelected(!selected);
    }

    return (
        <Container>
            <Box
                onClick={itemClickedHandler}
                sx={{
                    border: 0,
                    borderRadius: '24px',
                    borderBottomLeftRadius: selected ? '0px' : '24px',
                    borderBottomRightRadius: selected ? '0px' : '24px',
                    p: '16px',
                    m: '10px',
                    mb: selected ? '0px' : '10px',
                    backgroundColor: 'black'
                }}>
                {/*borderColor: 'red'*/}
                <Grid container>
                    <Grid item container xs={3} justifyContent="center">
                        {vehicleData.picture &&
                        <img src={vehicleData.picture} className={classes.pictureImg}/>
                        }
                        {vehicleData.reference.licencePlate &&
                        <Box
                            sx={{
                                marginTop: '10px',
                                border: 2,
                                borderRadius: '8px',
                                borderColor: 'orange',
                                backgroundColor: 'white',
                                p: '8px',
                                color: 'black'
                            }}>
                            <b>{vehicleData.reference.licencePlate}</b>
                        </Box>
                        }
                    </Grid>
                    <Grid item xs={7}>
                        <Typography sx={{fontSize: '18px'}}>{vehicleData.reference.brand}</Typography>
                        <Typography sx={{fontSize: '18px'}}>{vehicleData.reference.model}</Typography>
                        <Typography sx={{fontSize: '18px'}}>{vehicleData.reference.color}</Typography>
                    </Grid>
                    <Grid container item xs={2} alignContent="center" justifyContent="right">
                        <Link to={getEditPath(vehicleData.vehicleId)} onClick={(e) => e.stopPropagation()}>
                            <Button color="yellow">
                                <EditIcon sx={
                                    {
                                        color: 'orange',
                                        fontSize: 50,
                                    }}/>
                            </Button>
                        </Link>
                        <Button color="yellow" onClick={(e) => deleteVehicleHandler(e, vehicleData.vehicleId)}>
                            <DeleteIcon sx={
                                {
                                    color: 'orange',
                                    fontSize: 50,
                                }}/>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            {selected &&
            <Box sx={{
                border: 0,
                borderBottomLeftRadius: '24px',
                borderBottomRightRadius: '24px',
                p: '16px',
                m: '10px',
                mt: '0px',
                backgroundColor: '#666666'
            }}>
                {/*<VehicleItemSectionServiceHistory/>*/}
                <VehicleItemSectionInfo vehicleData={vehicleData}/>
                <VehicleItemSectionDocuments vehicleData={vehicleData} reloadHandler={reloadHandler}
                                             vehicleDocumentTypes={vehicleDocumentTypes}/>
            </Box>
            }
        </Container>
    )
}

const useStyles = makeStyles((theme) => ({
    pictureImg: {
        height: '100px',
        width: '100%',
        paddingLeft: '10px',
        paddingRight: '10px',
        objectFit: 'contain',
        borderRadius: '5px'
    },
    serviceImg: {
        height: '100px',
    }
}));