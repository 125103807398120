import {
    Button,
    Container,
    Grid,
    TextField,
    Typography
} from "@mui/material";

import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {Navigate, useOutletContext, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Swal from 'sweetalert2'
import logo from "../assets/logo-header.png";

function ResetPasswordNewPassword(props) {
    const classes = useStyles();

    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');

    const formRef = React.useRef();

    const [searchParams] = useSearchParams();
    const [token, setToken] = useState(null);
    const [resetDone, setResetDone] = useState(false);

    useEffect(() => {
        setToken(searchParams.get("token"));
    }, [])

    const sendEmailSubmitHandler = async e => {
        e.preventDefault();

        if (!formRef.current.reportValidity()) {
            return;
        }

        let email = formRef.current.elements['email'].value;
        let new_password = formRef.current.elements['new_password'].value;
        let new_password_again = formRef.current.elements['new_password_again'].value;

        if (new_password !== new_password_again) {
            Swal.fire(t('login.fail.title'), t('profile.error_password_different'), "error")
            return;
        }

        let data = {
            email: email,
            password: new_password,
            password_confirmation: new_password_again,
            token: token
        }
        sendPassword(data);
    }

    async function sendPassword(data) {
        return fetch(apiUri + 'password_reset_new_password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body:
                JSON.stringify(data)
        })
            .then(data => data.json())
            .then(data => {
                if (data.state == 'OK') {
                    Swal.fire(t('reset_password.' + data.message), '', 'success', {timer: 2000})
                        .then(() => {
                            setResetDone(true);
                        })
                } else {
                    Swal.fire(t('login.fail.title'), t('reset_password.' + data.message), "error");
                }
            })
    }

    return <>
        {
            resetDone ? <Navigate to='/login'/> :
                <Grid container
                      alignItems="center"
                      justifyContent="center">
                    <Container maxWidth='sm' className={classes.loginContainer} sx={{borderRadius: '16px'}}
                               disableGutters>
                        <Container>
                            <img src={logo} className={classes.logo}/>
                        </Container>
                        <Container>
                            <Typography align='center' variant='subtitle1'>
                                {t('reset_password_new_password.title')}
                            </Typography>
                            <div>
                                <form className={classes.form} noValidate onSubmit={sendEmailSubmitHandler}
                                      ref={formRef}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        name="email"
                                        label={t('reset_password.email')}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="new_password"
                                        name="new_password"
                                        label={t('profile.new_password')}
                                        type="password"
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="new_password_again"
                                        name="new_password_again"
                                        label={t('profile.new_password_again')}
                                        type="password"
                                    />
                                    <Button
                                        onClick={sendEmailSubmitHandler}
                                        fullWidth
                                        variant="contained"
                                        color="yellow"
                                        className={classes.loginBtn}
                                        sx={{mt: '15px', mb: '20px'}}
                                    >
                                        {t('reset_password.submit_btn')}
                                    </Button>
                                </form>
                            </div>
                        </Container>
                    </Container>
                </Grid>
        }
    </>
}

export default ResetPasswordNewPassword;

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        // backgroundColor: '#49545d',  // Gris clair 1
        background: 'linear-gradient(#49545D, #1C2326)'
    },
    input: {
        color: '#49545d'  // gris clair 2
    },
    loginContainer: {
        backgroundColor: '#292e32'
    },
    registerContainer: {
        backgroundColor: '#1d2124', // Gris foncé 1
        borderTop: 'solid #373B3E',
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    logo: {
        width: '90%',
        margin: '20px'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    loginBtn: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#fbc70f',
        color: 'black',
    },
    white: {
        color: 'white'
    },

    centerText: {
        alignItems: "center",
        display: "flex"
    }
}));