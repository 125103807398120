import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

const {useTranslation} = require("react-i18next");

function LanguageSelector() {
    const [t, i18n] = useTranslation('common');

    return <FormControl>
        <InputLabel id="demo-simple-select-label">{t('common.language')}</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={i18n.language}
            label={t('common.language')}
            onChange={(e) => {
                localStorage.setItem('language', e.target.value);
                i18n.changeLanguage(e.target.value);
            }}
        >
            <MenuItem value='en'>English</MenuItem>
            <MenuItem value='fr'>Français</MenuItem>
        </Select>
    </FormControl>
}

export default LanguageSelector;