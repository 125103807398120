import React, {useEffect} from 'react';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {Container, CssBaseline, Grid} from "@mui/material";
import {Link, Navigate, Outlet, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import LanguageSelector from "./components/LanguageSelector";
import {makeStyles} from "@mui/styles";
import './App.css';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        yellow: {
            main: '#fbc70f',
            dark: '#ff9c0d',
            contrastText: '#000000',
        },
        red: {
            main: '#8b0000',
            dark: '#800000',
            contrastText: '#FFFFFF',
        }
    }
});

function App() {
    const apiUri = process.env.REACT_APP_API_URI;
    const token = localStorage.getItem('accessToken');

    const location = useLocation();
    const navigate = useNavigate();

    const classes = useStyles();

    // Redirect
    if (location.pathname == '/') {
        if (token != null) {
            return (<Navigate to='/profile'/>)
        } else {
            return (<Navigate to='/login'/>)
        }
    }

    let gridProps = {container: true};
    // Non centré
    switch (location.pathname) {
        case '/profile':
        case '/profile_stripe':
        case '/stripe_callback':
        case '/dashboard':
        case '/location':
        case '/location/add':
        case '/location/edit':
            gridProps = {};
            break;
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <Grid className={classes.root} {...gridProps}>
                <Container style={{height: '100px'}}>
                    {process.env.NODE_ENV != 'production' &&
                    <>
                        <Link to="/login">Login</Link> |{" "}
                        <Link to="/register">Register</Link> |{" "}
                        <Link to="/reset_password">Reset password</Link> |{" "}
                        <Link to="/profile">Profile</Link> |{" "}
                        <Link to="/dashboard">Dashboard</Link>|{" "}
                        <Link to="/location">Location</Link>|{" "}
                        <Link to="/test_stripe">Test Stripe</Link>
                    </>
                    }
                    <CssBaseline/>
                    <Grid container justifyContent="flex-end" sx={{pt: "20px"}}>
                        <LanguageSelector/>
                    </Grid>
                </Container>

                <Container>
                    <Outlet context={[apiUri]}/>
                </Container>
            </Grid>
        </ThemeProvider>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        background: 'linear-gradient(#49545D, #1C2326)'
    }
}));

export default App;