import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    Autocomplete,
    Box,
    Button,
    Container,
    Grid,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {useOutletContext, useParams} from "react-router-dom";
import '../App.css';
import PictureUpload from "./PictureUpload";

import DefaultImage from "../assets/menu/vehicle_add/add-vehicle-white-black.png";

function VehicleStep2(props, ref) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');
    const formRef = React.useRef();
    const token = localStorage.getItem('accessToken');

    const [vehicleId, setVehicleId] = useState(null);

    const [vehicleData, setVehicleData] = useState(null);

    const [vinError, setVinError] = useState(null);
    const [country, setCountry] = useState('ch');

    const [brand, setBrand] = useState(null);
    const [model, setModel] = useState(null);

    const [carCategory, setCarCategory] = useState(null);

    // Does vin / brand / model / carCategory locked
    const [locked, setLocked] = useState(false);

    let params = useParams();

    useEffect(() => {
        getFromServer(params.vehicleId);

        props.previous_btn.current.style.display = 'none';
        // props.previous_btn.current.addEventListener('click', () => console.log('back'));
        props.next_btn.current.addEventListener('click', submitData);

        return () => {
            props.next_btn.current && props.next_btn.current.removeEventListener('click', submitData);
        }

    }, [])

    function submitData() {
        if (!formRef.current.reportValidity()) {
            return;
        }
        formRef.current.dispatchEvent(
            new Event("submit", {cancelable: true, bubbles: true})
        );
    }

    useImperativeHandle(ref, () => ({
        wantChangeTab(stepValue) {
            // TODO SAve or warn..
            props.changeStepFromContentHandler(stepValue);
        },
    }));

    // TODO redondant
    async function getFromServer(id) {
        return fetch(apiUri + 'vehicle_get/' + id, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(data => data.json())
            .then(data => {
                if (data.state === 'OK') {
                    setVehicleId(data.vehicle.vehicleId);
                    setVehicleData(data.vehicle);
                    // setCarCategory(data.vehicle.reference.vehicleCategory);

                    setLocked(data.vehicle.reference.isCheckVinDecoder == '1')

                    props.contentLoadedHandler(data.vehicle);
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            })
    }

    //
    async function sendToServer(data) {
        return fetch(apiUri + 'vehicle_register_step2', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body:
                JSON.stringify(data)
        })
            .then(data => data.json())
            .then(data => {
                if (data.state === 'OK') {
                    Swal.fire({
                        text: t('vehicle.form.success'),
                        icon: 'success',
                        timer: 2000,
                        customClass: {
                            container: 'my-swal'
                        }
                    })
                        .then(() => {
                            if (!props.isEditMode) {
                                props.changeStepFromContentHandler(-1)
                            }
                        });
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            })
    }


    function submitHandler(event) {
        if (!formRef.current.reportValidity()) {
            return;
        }

        // TODO vérifier la taille des textes
        let data = {
            id: vehicleId,
            first_date_traffic: formRef.current.elements['first_date_traffic'].value,
            licence_plate: formRef.current.elements['licence_plate'].value,
            motor: formRef.current.elements['motor'].value,
            color: formRef.current.elements['color'].value,
        }
        // console.log(data);
        sendToServer(data);
    }

    return (
        <Container>
            {vehicleData != null &&
            <>
                <form className="form-inline" onSubmit={submitHandler} ref={formRef}>
                    <Grid container>
                        <Grid item xs={6} sx={{p: '16px'}}>
                            <Box display="flex" justifyContent="center"
                                 sx={{
                                     border: 2,
                                     borderRadius: '8px',
                                     borderColor: 'orange',
                                     p: '8px',
                                     color: 'orange'
                                 }}>
                                <b>{t('vehicle.form.pictures_label')}</b>
                            </Box>
                            <Grid container justifyContent="center" sx={{mt: '8px'}}>
                                <PictureUpload
                                    picture_uri={vehicleData.picture}
                                    default_picture={DefaultImage}
                                    api_uri="vehicle_upload_picture"
                                    model_id={vehicleData.vehicleId}
                                    width="90%"
                                    height="auto"
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sx={{p: '16px'}}>
                            <Box display="flex" justifyContent="center"
                                 sx={{
                                     border: 2,
                                     borderRadius: '8px',
                                     borderColor: 'orange',
                                     p: '8px',
                                     color: 'orange'
                                 }}>
                                <b>{t('vehicle.form.info_label')}</b>
                            </Box>

                            <Box display="flex" justifyContent="left"
                                 sx={{border: 2, borderRadius: '8px', borderColor: 'orange', p: '8px', mt: '8px'}}>
                                {vehicleData.reference.brand}
                            </Box>

                            <Box display="flex" justifyContent="left"
                                 sx={{border: 2, borderRadius: '8px', borderColor: 'orange', p: '8px', mt: '8px'}}>
                                {vehicleData.reference.model}
                            </Box>

                            <TextField
                                margin="normal"
                                fullWidth
                                id="licence_plate"
                                label={t('vehicle.form.registration_number_label')}
                                defaultValue={vehicleData.reference.licencePlate}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="vin"
                                disabled={locked}
                                label={t('vehicle.form.vin')}
                                defaultValue={vehicleData.reference.vin}
                                disabled={true}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="car_category"
                                label={t('vehicle.form.car_category')}
                                defaultValue={vehicleData.reference.category.nameLong}
                                disabled={true}
                            />
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                margin="normal"
                                fullWidth
                                id="first_date_traffic"
                                type="date"
                                label={t('vehicle.form.first_traffic_date_label')}
                                defaultValue={vehicleData.firstDateTraffic}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="motor"
                                label={t('vehicle.form.motor_label')}
                                defaultValue={vehicleData.reference.motor}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="color"
                                label={t('vehicle.form.color_label')}
                                defaultValue={vehicleData.reference.color}
                            />
                        </Grid>
                    </Grid>
                </form>
            </>
            }
        </Container>
    )
};

export default forwardRef(VehicleStep2);