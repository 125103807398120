import {Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";

import React, {useEffect} from "react";
import {makeStyles} from "@mui/styles";

function ImageButton(props) {
    const [hover, setHover] = React.useState(false);

    const [selected, setSelected] = React.useState(false);

    const {
        height
    } = props;

    useEffect(() => {
        setSelected(props.selected);
    }, [props.selected])

    return <Button
        sx={
            selected ? {
                backgroundColor: 'orange', '&:hover': {
                    backgroundColor: 'orange'
                }
            } : null
        }
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => {
            if (props.onClickHandler != null) {
                props.onClickHandler();
            }
        }
        }
    >
        <img src={hover ? props.srcOver : props.src} style={{
            height: height
        }}/>
    </Button>
}

ImageButton.defaultProps = {
    height: '50px'
}

export default ImageButton;