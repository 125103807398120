import {useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Button, Container, FormControlLabel, FormGroup, Grid, Switch, Typography} from "@mui/material";
import Swal from "sweetalert2";
import LocationScheduleRow from "./LocationScheduleRow";
import LocationCalendarDay from "./LocationCalendarDay";

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

function LocationCalendar({locationId, schedulesData, saveSuccessHandler}, ref) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');
    const token = localStorage.getItem('accessToken');

    const [calendarData, setCalendarData] = useState(null);

    const [from, setFrom] = useState(null);
    const [onlyExceptions, setOnlyExceptions] = useState(false);

    const [temporaryClosed, setTemporaryClosed] = useState(null);

    useImperativeHandle(ref, () => ({
        async save() {
            return saveHandler();
        }
    }));

    useEffect(() => {
        getFromServer(locationId);
    }, [])

    function dataChangedHandler() {
        // setCalendarData(calendarData.slice(0));
        // Deep copy
        setCalendarData(JSON.parse(JSON.stringify(calendarData)));
    }

    function previousWeekClickedHandler(e) {
        saveHandler(true)
            .then(() => getFromServer(locationId, getSqlDateWithDayDiff(from, -7)));
    }

    function nextWeekClickedHandler(e) {
        saveHandler(true)
            .then(() => getFromServer(locationId, getSqlDateWithDayDiff(from, 7)));
    }

    function exceptionOnlyClickedHandler(e) {
        let checked = e.target.checked;
        saveHandler(true)
            .then(() => {
                getFromServer(locationId, null, null, checked);
            })
    }

    function getSqlDateWithDayDiff(sqlDate, diff) {
        let result = new Date(sqlDate);
        result.setDate(result.getDate() + diff);
        return result.getFullYear() + '-' + (result.getMonth() + 1) + '-' + result.getDate();
    }

    function getScheduleDataForDay(calendarDayData) {
        // console.log(calendarDayData);
        // console.log(schedulesData);

        let date = new Date(calendarDayData.dateDay);
        let dayOfWeek = (date.getDay() + 6) % 7;  // 0 => Monday , 6=> Sunday
        // console.log("DAYOFWEEK=" + dayOfWeek);

        return (JSON.parse(JSON.stringify(schedulesData[dayOfWeek])));

        // return schedulesData[dayOfWeek];
    }

    async function getFromServer(locationId, from = null, to = null, onlyExceptions = false) {
        let url = apiUri + 'location_calendar/' + locationId;
        if (from != null) {
            url += '/' + from;
            if (to != null) {
                url += '/' + to;
            }
        }

        if (onlyExceptions) {
            url += "?onlyExceptions=1";
        }

        return fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(data => data.json())
            .then(data => {
                if (data.state === 'OK') {
                    if (data.onlyExceptions == '1') {
                        setOnlyExceptions(true);
                        setFrom(null);
                    } else {
                        setOnlyExceptions(false);
                        setFrom(data.calendar[0].dateDay);
                    }
                    setTemporaryClosed(data.temporary_closed == '1');
                    setCalendarData(data.calendar);
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            })
    }

    async function saveHandler(quiet = false) {
        let data = {
            "location_id": locationId,
            "temporary_closed": temporaryClosed ? '1' : '0',
            "calendar": calendarData
        };

        return sendToServer(data, quiet);
    }

    async function sendToServer(data, quiet = false) {
        return fetch(apiUri + 'location_calendar', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body:
                JSON.stringify(data)
        })
            .then(data => data.json())
            .then(data => {
                if (data.state === 'OK') {
                    if (!quiet) {
                        Swal.fire({
                            text: t('location.calendar.save_success'),
                            icon: 'success',
                            timer: 2000,
                            customClass: {
                                container: 'my-swal'
                            }
                        })
                            .then(() => saveSuccessHandler());
                    }
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            })
    }

    let isCurrentWeek = false;
    if(calendarData)
    {
        calendarData.forEach(calendarDayData => {
            if ('isToday' in calendarDayData && calendarDayData.isToday == '1') {
                isCurrentWeek = true;
            }
        });
    }

    return (<Container maxWidth={false}>
        <Grid container justifyContent="center" sx={{"mt": 2}}>
            <Typography>{t('location.calendar.label').toUpperCase()}</Typography>
        </Grid>
        <Grid container justifyContent="center">
            <FormGroup><FormControlLabel control={<Switch
                checked={onlyExceptions}
                onChange={(e) => exceptionOnlyClickedHandler(e)}/>}
                                         label={t('location.calendar.show_only_exceptions_label')}/></FormGroup>
        </Grid>

        {calendarData &&
        (
            <>
                <Grid container>
                    <Grid item xs={1 / 2} container alignContent="center">
                        {!onlyExceptions && !isCurrentWeek &&
                        <Button color="yellow" onClick={previousWeekClickedHandler}>
                            <NavigateBeforeIcon sx={
                                {
                                    color: 'orange',
                                    fontSize: 60,
                                }}/>
                        </Button>
                        }
                    </Grid>
                    {calendarData.map((calendarDayData, index) => {
                        return <Grid item xs={11 / 7} key={index}>
                            <LocationCalendarDay calendarDayData={calendarDayData}
                                                 dataChangedHandler={dataChangedHandler}
                                                 scheduleData={getScheduleDataForDay(calendarDayData)}/>
                        </Grid>
                    })}
                    <Grid item xs={1 / 2} container alignContent="center">
                        {!onlyExceptions &&
                        <Button color="yellow" onClick={nextWeekClickedHandler}>
                            <NavigateNextIcon sx={
                                {
                                    color: 'orange',
                                    fontSize: 60,
                                }}/>
                        </Button>}
                    </Grid>
                </Grid>
            </>)
        }

        {/*<Button*/}
        {/*    variant="contained"*/}
        {/*    color="yellow"*/}
        {/*    onClick={saveHandler}*/}
        {/*>*/}
        {/*    SAVE TEST*/}
        {/*</Button>*/}

        <Grid container justifyContent="center" sx={
            {
                backgroundColor: temporaryClosed ? 'orange' : ''
            }
        }>
            <FormGroup><FormControlLabel control={<Switch
                checked={temporaryClosed}
                onChange={function (e) {
                    setTemporaryClosed(e.target.checked);
                }}/>} label={t('location.calendar.temporary_close_label')}/></FormGroup>
        </Grid>

    </Container>);
}

export default forwardRef(LocationCalendar);