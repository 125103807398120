import {useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button, Checkbox, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField} from "@mui/material";
import React, {useState} from "react";
import * as PropTypes from "prop-types";
import {withStyles} from "@mui/styles";
import LocationOptionRow from "./LocationOptionRow";

function LocationServiceOptions(props) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');

    const {classes} = props;

    // const carCategoryData = props.carCategoryData;
    const serviceOptionsData = props.serviceOptionData;

    return (
        <>
            {t('location.options.label')}
            <Paper className={classes.tableParent}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{"minWidth": 150}}>
                                {t('location.options.option_label')}
                            </TableCell>
                            <TableCell align="right"
                                       style={{"minWidth": 150}}>{t('location.options.option_description')}</TableCell>
                            <TableCell align="right">{t('location.options.option_unique_price')}</TableCell>
                            {props.serviceData.prices.map((priceData, index) => {
                                return <TableCell
                                    className={classes.priceCell}
                                    key={index}>
                                    {priceData.categoryName}
                                </TableCell>
                            })}
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {serviceOptionsData.map((optionData, index) => {
                            return <LocationOptionRow
                                key={index}
                                option={optionData}
                                serviceData={props.serviceData}
                                deleteOptionHandler={(e) => props.deleteOptionHandler(e, optionData, serviceOptionsData)}/>
                        })}
                    </TableBody>
                </Table>
            </Paper>
            <Button
                fullWidth
                variant="contained"
                color="yellow"
                sx={{mt: '15px', mb: '20px'}}
                onClick={props.newOptionHandler}
            >
                {t('location.options.button_add_option')}
            </Button>
        </>
    );
}

const styles = theme => ({
    tableParent: {
        width: "100%",
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    priceCell: {
        minWidth: 100
    }
});

LocationServiceOptions.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LocationServiceOptions);