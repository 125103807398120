import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Loader} from '@googlemaps/js-api-loader';
import {Button, Container, Grid, MenuItem, Select, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {useOutletContext, useParams} from "react-router-dom";
import '../App.css';

const loader = new Loader({
    apiKey: "AIzaSyD4oC9kowowAPljAxy6zVoyPA199sPrYSk",
    // apiKey: "AIzaSyC6PoFdA-wJSpWJi7ve_qoVxFfmLIhMTWk",
    version: "weekly",
    libraries: ["places"]
});

function LocationGeo(props, ref) {
    const [apiUri] = useOutletContext();
    const googlemapDiv = useRef(null)

    const [google, setGoogle] = useState(null);
    const [map, setMap] = useState(null);
    const [marker, setMarker] = useState(null);
    const [geocoder, setGeocoder] = useState(null);

    // Special case for select..
    const defaultCountry = 'switzerland'
    const [country, setCountry] = useState(defaultCountry);

    const [searchOk, setSearchOK] = useState(false);
    const [geoValidated, setGeoGeoValidated] = useState(false);

    const [lat, setLat] = useState(null);
    const [lon, setLon] = useState(null);

    const [locationId, setLocationId] = useState(null);
    const [locationData, setLocationData] = useState(null);


    const [googleAddress, setGoogleAddress] = useState(null);

    const {t} = useTranslation('common');
    const formRef = React.useRef();

    const token = localStorage.getItem('accessToken');


    let params = useParams();

    // Refs
    const locationIdRef = useRef(null);
    const searchOkRef = useRef(null);
    const geoValidatedRef = useRef(null);
    const latRef = useRef(null);
    const lonRef = useRef(null);
    const countryRef = useRef(null);
    const googleAddressRef = useRef(null);
    const editModeRef = useRef(null);

    editModeRef.current = props.isEditMode;
    locationIdRef.current = locationId;
    searchOkRef.current = searchOk;
    geoValidatedRef.current = geoValidated;
    latRef.current = lat;
    lonRef.current = lon;
    countryRef.current = country;
    googleAddressRef.current = googleAddress;

    useEffect(() => {
        if (params.locationId != null) {
            getFromServer(params.locationId);
        } else {
            props.contentLoadedHandler(null);   // To trigger stepper vue
        }

        props.previous_btn.current.style.display = 'none';
        props.next_btn.current.addEventListener('click', submitData);
        return () => {
            props.next_btn.current && props.next_btn.current.removeEventListener('click', submitData);
        }

    }, []);

    useEffect(() => {
        if (props.contentLoaded) {
            // Init googlemap
            const defaultMapOptions = {
                center: center,
                zoom: 11
            };

            loader.load().then((google) => {
                const map = new google.maps.Map(
                    googlemapDiv.current,
                    defaultMapOptions);

                const marker = new google.maps.Marker(
                    {
                        map: map,
                        position: center
                    }
                );

                const geocoder = new google.maps.Geocoder();

                setGoogle(google);
                setMap(map);
                setMarker(marker);
                setGeocoder(geocoder);
            });
        }
    }, [props.contentLoaded]);

    // Chargement de la carte initiale lors d'un edit
    useEffect(() => {
        if (lat != null) {
            geocodeReachFromLatLon();
        }
    }, [geocoder]);

    useEffect(() => {
    }, [geoValidated]);

    useImperativeHandle(ref, () => ({
        wantChangeTab(stepValue) {
            // console.log('want change tab to ' + stepValue);
            // TODO SAve or warn..
            props.changeStepFromContentHandler(stepValue);
        },
    }));

    async function getFromServer(id) {
        return fetch(apiUri + 'location_get/' + id, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(data => data.json())
            .then(data => {
                if (data.state === 'OK') {
                    setLocationId(data.location.locationId);
                    setLocationData(data.location);
                    setCountry(data.location.country);
                    setLat(data.location.latitude);
                    setLon(data.location.longitude);
                    setSearchOK(true);
                    setGeoGeoValidated(true);
                    props.contentLoadedHandler(data.location);
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            })
    }

    async function sendToServer(data) {
        return fetch(apiUri + 'location_register', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body:
                JSON.stringify(data)
        })
            .then(data => data.json())
            .then(data => {
                if (data.state === 'OK') {

                    let newLocationId = data.locationId;

                    Swal.fire({
                        text: t('location.location.success'),
                        icon: 'success',
                        timer: 2000,
                        customClass: {
                            container: 'my-swal'
                        }
                    })
                        .then(() => {
                                // console.log('EM=', editModeRef.current);
                                if (!editModeRef.current) {
                                    props.changeStepFromContentHandler(1, newLocationId);
                                }
                            }
                        );
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            })
    }

    function adressSubmitHandler(submitEvent) {
        submitEvent.preventDefault();

        let address = formRef.current.elements['address'].value;
        let postalCode = formRef.current.elements['postal_code'].value;
        let city = formRef.current.elements['city'].value;

        let completeAddress = address + ' ' + postalCode + ' ' + city + ' ' + country;

        geocodeAddress(completeAddress);
    }

    function mapValidatedHandler(event) {
        // console.log("SETVALIDATED");
        setGeoGeoValidated(true);
    }

    function submitData() {
        // console.log('VVV' + geoValidated);
        if (!searchOkRef.current) {
            Swal.fire({
                text: t('location.location.warning_must_search'),
                icon: 'warning',
                customClass: {
                    container: 'my-swal'
                }
            });
            return;
        }

        if (!geoValidatedRef.current) {
            Swal.fire({
                text: t('location.location.warning_must_validate_geo'),
                icon: 'warning',
                customClass: {
                    container: 'my-swal'
                }
            });
            return;
        }

        let data = {
            id: locationIdRef.current,
            label: formRef.current.elements['label'].value,
            address: formRef.current.elements['address'].value,
            postal_code: formRef.current.elements['postal_code'].value,
            city: formRef.current.elements['city'].value,
            country: countryRef.current,
            address_gmap: googleAddressRef.current,
            lat: latRef.current,
            lon: lonRef.current
        }

        sendToServer(data);
    }

    function geocodeAddress(address) {
        geocoder.geocode({'address': address}, function handleResults(results, status) {
            if (status === 'OK') {  // TODO google code ?
                let result = results[0];
                map.setCenter(result.geometry.location);
                marker.setPosition(result.geometry.location);
                setLat(result.geometry.location.lat());
                setLon(result.geometry.location.lng());
                setGoogleAddress(result.formatted_address);
                // console.log(result);
                setSearchOK(true);
            }
        });
    }

    function geocodeReachFromLatLon() {
        console.log(lat + ";" + lon);
        if (lat != null && lon != null && geocoder != null) {
            geocoder.geocode({
                'location': {
                    lat: parseFloat(lat),
                    lng: parseFloat(lon)
                }
            }, function handleResults(results, status) {
                if (status === 'OK') {  // TODO google code ?
                    let result = results[0];
                    map.setCenter(result.geometry.location);
                    marker.setPosition(result.geometry.location);
                    setLat(result.geometry.location.lat());
                    setLon(result.geometry.location.lng());
                    setGoogleAddress(result.formatted_address);
                    setSearchOK(true);
                }
            });
        }
    }

    function resetGeo() {
        setSearchOK(false);
        setGeoGeoValidated(false);
    }

    return (
        <Container>
            {props.contentLoaded &&
            <form className="form-inline" onSubmit={adressSubmitHandler} ref={formRef}>
                <Grid container sx={{
                    mt: '32px'
                }}>
                    <Grid item xs={6} sx={
                        {
                            p: '16px'
                        }}>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="label"
                            defaultValue={locationData ? locationData.name : ''}
                            label={t('location.location.location_name')}
                            required
                        />
                        <Select
                            id="country"
                            // label={t('location.location.location_country')}
                            value={country}
                            onChange={
                                e => {
                                    setCountry(e.target.value);
                                    resetGeo();
                                }
                            }
                        >
                            <MenuItem value="switzerland">{t('countries.switzerland')}</MenuItem>
                            <MenuItem selected value="france">{t('countries.france')}</MenuItem>
                        </Select>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="address"
                            defaultValue={locationData ? locationData.address : ''}
                            label={t('location.location.location_address')}
                            onChange={
                                e => {
                                    resetGeo();
                                }
                            }
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="postal_code"
                            defaultValue={locationData ? locationData.postalCode : ''}
                            label={
                                country == 'switzerland' ?
                                    t('location.location.location_npa')
                                    :
                                    t('location.location.location_postal_code')
                            }
                            onChange={
                                e => {
                                    resetGeo();
                                }
                            }
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="city"
                            defaultValue={locationData ? locationData.city : ''}
                            label={t('location.location.location_city')}
                            onChange={
                                e => {
                                    resetGeo();
                                }
                            }
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="yellow"
                            sx={{mt: '15px', mb: '20px'}}
                        >
                            {t('location.location.button_search')}
                        </Button>
                        {googleAddress && t('location.location.location_found_label') + googleAddress}
                    </Grid>
                    <Grid item xs={6} sx={{p: '16px'}}>
                        <div
                            ref={googlemapDiv}
                            style={geoValidated ? mapContainerValidated : containerStyle}>
                        </div>
                        {
                            searchOk &&
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{mt: '15px', mb: '20px'}}
                                onClick={mapValidatedHandler}
                            >
                                {
                                    geoValidated ?
                                        t('location.location.button_check_done')
                                        :
                                        t('location.location.button_check')
                                }
                            </Button>
                        }

                    </Grid>
                </Grid>
            </form>
            }
        </Container>
    )
};

const containerStyle = {
    width: '100%',
    height: '360px',
    marginTop: '16px',
    border: '5px'
};

const mapContainerValidated = {
    width: '100%',
    height: '360px',
    marginTop: '16px',
    border: '5px solid green'
}

const center = {
    lat: 51.5085300,
    lng: -0.1257400
};

const hidden = {
    display: 'none'
}

export default forwardRef(LocationGeo);