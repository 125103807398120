import {Box, Container, Grid, InputAdornment, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

export default function LocationCarItem(props) {
    const classes = useStyles();
    const {t} = useTranslation('common');

    const [price, setPrice] = useState(props.priceData.price);

    useEffect(() => {
        if (props.uniquePrice) {
            setPrice(props.uniquePriceValue);
        }
    }, [props.uniquePrice, props.uniquePriceValue]);
    //
    useEffect(() => {
        // console.log(props.priceData);
        if (props.priceData) {
            setPrice(props.priceData.price);
        }
    }, [props.priceData]);


    // Merdique !!
    useEffect(() => {
        // Update model
        props.priceData.price = price;
    }, [price]);

    return (
        <Container sx={{border: 1, p: 1}}>
            <Grid container justifyContent="center">
                <Grid container style={{minHeight: 50}} alignContent="center" justifyContent="center">
                    <img src={props.priceData.icon} className={classes.icon}/>
                </Grid>
                <TextField
                    type="number"
                    margin="normal"
                    size="small"
                    fullWidth
                    id="label"
                    label={t('location.services.price')}
                    onChange={(e) => {
                        let newPrice = parseFloat(e.target.value);
                        if (newPrice < 0) {
                            newPrice = 0;
                        }
                        setPrice(newPrice);
                        props.priceChangedHandler(e);
                        props.priceData.price = newPrice;
                    }}
                    value={price||''}
                    // defaultValue={serviceData.label}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{props.currency}</InputAdornment>
                    }}
                />
                <span className={classes.text}>{props.priceData.categoryName}</span>
            </Grid>
        </Container>
    )
}

const useStyles = makeStyles((theme) => ({
    icon: {
        width: '80px',
        height: 'auto'
    },
    text: {
        'white-space': 'nowrap'
    }
}));