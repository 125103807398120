import React, {useEffect, useState} from 'react';
import {
    Box, Button, Grid, TextField, Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Navigate, useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Swal from 'sweetalert2'
import ProfilePictureUpload from "./ProfilePictureUpload";
import DeleteAccount from "./DeleteAccount";

export default function Profile(props) {
    const classes = useStyles();
    const token = localStorage.getItem('accessToken');
    const {t} = useTranslation('common');
    const [apiUri] = useOutletContext();
    const [loaded, setLoaded] = useState(false);
    const [userData, setUserData] = useState(null);
    const [editPassword, setEditPassword] = useState(false);
    const formRef = React.useRef();

    const [logoutDone, setLogoutDone] = useState(false);

    useEffect(() => {
        props.setLabel('profile.title');
        accountGet();
    }, [])

    if (token == null) {
        return ( //
            <Navigate to='/login'/>
        );
    }

    async function accountGet() {
        return fetch(apiUri + 'account', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(data => data.json())
            .then(data => {
                if (data.state === 'OK') {
                    setUserData(data.user);
                    setLoaded(true)
                } else {
                    Swal.fire(t('login.fail.title'), t(data.message), "error");
                }
            })
    }

    function logoutClickedHandler() {
        return fetch(apiUri + 'logout', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(data => data.json())
            .then(data => {
                if (data.state == 'OK') {
                    Swal.fire(t('logout.success'), '', 'success', {timer: 2000})
                        .then(() => {
                            localStorage.removeItem('accessToken');
                            setLogoutDone(true);
                        })
                } else {
                    Swal.fire(t('login.fail.title'), t(data.message), "error");
                }
            })
    }

    async function changePassword(data) {
        return fetch(apiUri + 'change_password', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body:
                JSON.stringify(data)
        })
            .then(data => data.json())
            .then(data => {
                if (data.state == 'OK') {
                    Swal.fire('', t('profile.success_change_password'), "success", {
                        buttons: false,
                        timer: 2000,
                    }).then(() => {
                        setEditPassword(false);
                    })
                } else {
                    Swal.fire(t('login.fail.title'), t(data.message), "error");
                }
            })
    }

    const changePasswordSubmitHandler = async e => {
        e.preventDefault();

        if (!formRef.current.reportValidity()) {
            return;
        }
        let new_password = formRef.current.elements['new_password'].value;
        let new_password_again = formRef.current.elements['new_password_again'].value;

        if (new_password != new_password_again) {
            Swal.fire(t('login.fail.title'), t('profile.error_password_different'), "error");
            return;
        }

        let data = {
            current_password: formRef.current.elements['current_password'].value,
            new_password: new_password
        }

        changePassword(data);
    }

    if (logoutDone) {
        return <Navigate to='/login'/>
    }

    return (
        <>
            {/*<Avatar src={user.avatar} className={classes.large} />*/}
            <Typography variant="h4">
                {t("profile.my_account")}
            </Typography>
            {/*<Button variant="outlined">Edit</Button>*/}
            <Box width="100%" sx={{mt: 1}}/>
            {loaded &&
            <>

                <Button
                    variant="contained"
                    color="yellow"
                    sx={{mt: '15px', mb: '20px'}}
                    onClick={logoutClickedHandler}
                >
                    {t('profile.btn_logout')}
                </Button>
                <Box width="100%" sx={{mt: 1}}/>
                <ProfilePictureUpload profile_picture={userData.profile_picture}/>
                <Box width="100%" sx={{mt: 1}}/>
                <div>{userData.firstName} {userData.lastName}</div>
                <Box width="100%" sx={{mt: 1}}/>
                <div><b>{t('profile.email')}</b></div>
                <Box width="100%"/>
                <div>{userData.email}</div>
                <Box width="100%" sx={{mt: 1}}/>
                {editPassword ?
                    <>
                        <form className={classes.form} noValidate onSubmit={changePasswordSubmitHandler}
                              ref={formRef}>
                            <Grid container alignItems="center"
                                  justifyContent="center">
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="current_password"
                                    name="current_password"
                                    label={t('profile.current_password')}
                                    type="password"
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="new_password"
                                    name="new_password"
                                    label={t('profile.new_password')}
                                    type="password"
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="new_password_again"
                                    name="new_password_again"
                                    label={t('profile.new_password_again')}
                                    type="password"
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="yellow"
                                    className={classes.loginBtn}
                                    sx={{mt: '15px', mb: '20px'}}
                                >
                                    {t('profile.edit_password')}
                                </Button>
                            </Grid>
                        </form>
                    </>
                    :
                    <>
                        <Button variant="outlined"
                                onClick={() => setEditPassword(true)}>{t("profile.edit_password")}
                        </Button>
                    </>
                }
                <Box width="100%" sx={{mt: 1}}/>
                <DeleteAccount/>
            </>
            }
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#292e32'
    },
    cardContent: {
        backgroundColor: '#292e32'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    large: {
        width: theme.spacing(20),
        height: theme.spacing(20),
    },
    logo: {
        height: '100px',
        margin: '10px'
    },
    menuBtnImg: {
        height: '50px'
    },
    divider: {
        background: theme.palette.yellow.main,
    },
    menuPage: {
        color: theme.palette.yellow.main,
        paddingRight: '20px'
    }
}));
