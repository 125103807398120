import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {useOutletContext} from "react-router-dom";
import Swal from 'sweetalert2'
import {useTranslation} from "react-i18next";

import btnEdit from "../assets/buttons/edit/write-white-yellow.svg";
import defaultImage from "../assets/menu/profile/profil-b-g.png";

function ProfilePictureUpload(props) {
    const classes = useStyles();
    const token = localStorage.getItem('accessToken');
    const [imageUrl, setImageUrl] = useState(null);

    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');

    useEffect(() => {
        setImageUrl(props.profile_picture);
    }, [])

    const handleUploadClick = event => {
        var file = event.target.files[0];

        let data = new FormData();
        data.append("file", file);
        return fetch(apiUri + "upload_user_picture", {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
            },
            body: data
        })
            .then(data => data.json())
            .then(data => {
                if (data.state == 'OK') {
                    setImageUrl(data.profile_picture)
                } else {
                    Swal.fire(t('login.fail.title'), t(data.message), "error");
                }
            });
    };

    return <>
        <input
            accept="image/*"
            className={classes.uploadInput}
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleUploadClick}
        />
        <label htmlFor="contained-button-file">
            <img src={imageUrl == null ? defaultImage : imageUrl} className={classes.profilePictureImg}/>
            <img src={btnEdit} className={classes.editBtn}/>
        </label>
    </>
}

export default ProfilePictureUpload;

const useStyles = makeStyles((theme) => ({
    profilePictureImg: {
        height: '100px',
        width: '100px',
        objectFit: 'contain',
        borderRadius: '5px',
        cursor: 'pointer'
    },
    editBtn: {
        height: '32px',
        cursor: 'pointer',
        marginLeft: '5px'
    },
    uploadInput: {
        display: "none"
    }
}));