import React, {forwardRef, useEffect, useState} from 'react';

import BerlineCar from "../assets/cars/Berline.png";
import BerlineXLCar from "../assets/cars/BerlineXL.png";
import CitadineCar from "../assets/cars/Citadine.png";
import CoupeCar from "../assets/cars/Coupe.png";
import FamilialeCar from "../assets/cars/Familiale.png";
import FamilialeBigCar from "../assets/cars/GrosseFamiliale.png";
import SuvBigCar from "../assets/cars/GrosSuv.png";
import SuvSmallCar from "../assets/cars/petitSuv.png";
import LuxeSupercarCar from "../assets/cars/LuxeSupercar.png";
import MicroCar from "../assets/cars/Micro.png";
import Swal from "sweetalert2";
import {useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";

function VehicleDocumentTypesService({setDocumentTypes}) {
    const token = localStorage.getItem('accessToken');
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');

    // const [documentTypes, setDocumentTypes] = useState(null);

    // static getCarCategoryById(id) {
    //     return CarService.carCategories.find(category => category.id == id);
    // }

    useEffect(() => {
        getVehicleDocumentTypes();
    }, [])

    function getVehicleDocumentTypes() {
        return fetch(apiUri + 'vehicle_document_types', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(data => data.json())
            .then(data => {
                if (data.state === 'OK') {
                    setDocumentTypes(data.document_types);
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            })
    }
}

export default VehicleDocumentTypesService;