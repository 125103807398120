import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Grid, InputAdornment,
    Stack, TextareaAutosize, TextField,
    Typography
} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import InfoIcon from "../assets/buttons/info/info-white-black.png"
import SaveIcon from "../assets/buttons/save/save-white-yellow.png"
import SaveIcon2 from "../assets/buttons/save/save-white-black.png"
import ImageButton from "./ImageButton";
import Swal from "sweetalert2";
import {useOutletContext} from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VehicleItemSection from "./VehicleItemSection";


// function ExpandMoreIcon() {
//     return null;
// }

export default function VehicleItemSectionInfo({vehicleData, selected = true}) {
    const [apiUri] = useOutletContext();
    const token = localStorage.getItem('accessToken');
    const {t} = useTranslation('common');
    const formRef = React.useRef();

    function saveClickedHandler(e) {
        let data = {
            id: vehicleData.vehicleId,
            notes: formRef.current.elements['notes'].value
        }

        fetch(apiUri + 'vehicle_notes', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body:
                JSON.stringify(data)
        })
            .then(data => data.json())
            .then(data => {
                if (data.state === 'OK') {
                    Swal.fire({
                        text: t('vehicle.form.success'),
                        icon: 'success',
                        timer: 2000,
                        customClass: {
                            container: 'my-swal'
                        }
                    })
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            })
    }

    return (
        <VehicleItemSection
            icon={InfoIcon}
            label={t('vehicle.item.info.label')}
            detailContent={<>
                <Grid item xs={4}>
                    <Stack>
                        <Typography>{t('vehicle.item.info.vin_label')} {vehicleData.reference.vin}</Typography>
                        <Typography>{t('vehicle.item.info.first_traffic_date_label')} {vehicleData.firstDateTraffic}</Typography>
                        <Typography>{t('vehicle.item.info.motor_label')} {vehicleData.reference.motor}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={8}>
                    <form ref={formRef}>
                        <TextField
                            id="notes"
                            inputProps={{
                                style: {color: "black"}
                            }}
                            InputProps={{
                                endAdornment:
                                    <ImageButton src={SaveIcon2}
                                                 srcOver={SaveIcon}
                                                 height="30px"
                                                 onClickHandler={saveClickedHandler}/>
                            }}
                            multiline
                            fullWidth
                            minRows={2}
                            label={t('vehicle.item.info.note_label')}
                            defaultValue={vehicleData.reference.notes}
                        />
                    </form>
                </Grid>
            </>}
        />
    )
}