import React, {useEffect, useState} from 'react';
import {
    Box, Button, Grid, Typography
} from "@mui/material";
import {Link, Navigate, Outlet, useLocation, useNavigate, useOutlet, useOutletContext} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Swal from 'sweetalert2'
import VehicleItem from "./VehicleItem";
import VehicleDocumentTypesService from "../services/VehicleDocumentTypesService";

export default function VehicleIndex(props) {
    const token = localStorage.getItem('accessToken');
    const {t} = useTranslation('common');
    const [apiUri] = useOutletContext();
    const [loaded, setLoaded] = useState(false);
    const [vehicleList, setVehicleList] = useState([]);
    const outlet = useOutlet()
    const navigate = useNavigate();

    const [vehicleDocumentTypes, setVehicleDocumentTypes] = useState(null);

    useEffect(() => {
        props.setLabel('vehicle.title');
        vehicleIndexGet();
    }, [])

    if (token == null) {
        return ( //
            <Navigate to='/login'/>
        );
    }

    async function vehicleIndexGet() {
        return fetch(apiUri + 'vehicle_index', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(data => data.json())
            .then(data => {
                if (data.state == 'OK') {
                    setLoaded(true);
                    setVehicleList(data.vehicles);
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            })
    }

    function vehicleUpdatedHandler() {
        navigate("/vehicle");
        vehicleIndexGet();
    }

    function deleteVehicleHandler(e, id) {
        e.stopPropagation();
        Swal.fire({
            title: t('vehicle.delete_confirmation'),
            showCancelButton: true,
            confirmButtonColor: '#dd6b55',
            confirmButtonText: t('common.yes'),
            cancelButtonText: t('common.no'),
        }).then((result) => {
            if (result.isConfirmed) {
                deleteVehicle(id);
            }
        })
    }

    function reloadHandler() {
        vehicleIndexGet();
    }

    async function deleteVehicle(id) {
        return fetch(apiUri + 'vehicle', {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body:
                JSON.stringify({id: id})
        })
            .then(data => data.json())
            .then(data => {
                if (data.state == 'OK') {
                    Swal.fire(t('vehicle.delete_success'), '', 'success', {timer: 2000})
                        .then(() => {
                            vehicleUpdatedHandler();
                        })
                } else {
                    Swal.fire(t('login.fail.title'), t(data.message), "error");
                }
            })
    }

    return (
        <>
            <VehicleDocumentTypesService setDocumentTypes={setVehicleDocumentTypes} />
            <Grid justifyContent="center" container>
                <Link to="/vehicle/add" style={{textDecoration: 'none'}}>
                    <Button
                        variant="contained"
                        color="yellow"
                        sx={{mt: '15px', mb: '20px'}}
                    >
                        {t('vehicle.button_create_vehicle')}
                    </Button>
                </Link>
            </Grid>
            <Box width="100%" sx={{mt: 1}}/>
            {loaded &&
            <>
                {vehicleList.length == 0 ?
                    <>
                        <h3>
                            {t("vehicle.empty_list_message")}
                        </h3>
                    </>
                    :
                    <>
                        {
                            vehicleList.map((vehicle, i) => {
                                return (<VehicleItem
                                    key={i}
                                    vehicleData={vehicle}
                                    deleteVehicleHandler={deleteVehicleHandler}
                                    reloadHandler={reloadHandler}
                                    vehicleDocumentTypes={vehicleDocumentTypes}
                                />);
                            })
                        }
                    </>
                }

                <Grid justifyContent="center" container>
                    <Link to="/vehicle/add" style={{textDecoration: 'none'}}>
                        <Button
                            variant="contained"
                            color="yellow"
                            sx={{mt: '15px', mb: '20px'}}
                        >
                            {t('vehicle.button_create_vehicle')}
                        </Button>
                    </Link>
                </Grid>

                <Outlet context={[apiUri, vehicleUpdatedHandler]}/>
            </>
            }
        </>
    );
}