import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Box, Button, Container, Grid, MenuItem, Select, Switch, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {useOutletContext, useParams} from "react-router-dom";
import '../App.css';
import PictureUpload from "./PictureUpload";

import defaultImage from "../assets/menu/location/emplacement-b-g.png";

function LocationInfo(props, ref) {
    const [apiUri] = useOutletContext();
    const {t} = useTranslation('common');
    const formRef = React.useRef();
    const token = localStorage.getItem('accessToken');

    const [locationId, setLocationId] = useState(null);
    const [contactId, setContactId] = useState(null);

    const [locationData, setLocationData] = useState(null);

    const [privateLocation, setPrivateLocation] = useState(false);
    const [mobileLocation, setMobileLocation] = useState(false);

    let params = useParams();

    useEffect(() => {
        if (params.locationId != null) {
            getFromServer(params.locationId);
        }

        props.previous_btn.current.style.display = 'none';
        // props.previous_btn.current.addEventListener('click', () => console.log('back'));
        props.next_btn.current.addEventListener('click', submitData);

        return () => {
            props.next_btn.current && props.next_btn.current.removeEventListener('click', submitData);
        }

    }, [])

    function submitData() {
        if (!formRef.current.reportValidity()) {
            return;
        }
        formRef.current.dispatchEvent(
            new Event("submit", {cancelable: true, bubbles: true})
        );
    }

    useImperativeHandle(ref, () => ({
        wantChangeTab(stepValue) {
            // TODO SAve or warn..
            props.changeStepFromContentHandler(stepValue);
        },
    }));

    // TODO redondant
    async function getFromServer(id) {
        return fetch(apiUri + 'location_get/' + id, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(data => data.json())
            .then(data => {
                if (data.state === 'OK') {
                    setLocationId(data.location.locationId);
                    setLocationData(data.location);
                    setPrivateLocation(getOptionExists(data.location, 1));
                    setMobileLocation(getOptionExists(data.location, 2));
                    props.contentLoadedHandler(data.location);
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            })
    }

    //
    async function sendToServer(data) {
        return fetch(apiUri + 'location_info', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body:
                JSON.stringify(data)
        })
            .then(data => data.json())
            .then(data => {
                if (data.state === 'OK') {
                    Swal.fire({
                        text: t('location.info.success'),
                        icon: 'success',
                        timer: 2000,
                        customClass: {
                            container: 'my-swal'
                        }
                    })
                        .then(() => {
                                if (!props.isEditMode) {
                                    props.changeStepFromContentHandler(2);
                                }
                            }
                        );
                } else {
                    Swal.fire({
                        text: t(data.message),
                        icon: 'error',
                        customClass: {
                            container: 'my-swal'
                        }
                    });
                }
            })
    }


    function submitHandler(event) {
        event.preventDefault();
        let data = {
            id: locationId,
            private_location: formRef.current.elements['private_location'].checked,
            private_location_share_code: formRef.current.elements['private_location_share_code'].value,
            mobile_location: formRef.current.elements['mobile_location'].checked,
            mobile_location_distance: formRef.current.elements['mobile_location_distance'].value,
            contact: {
                id: contactId,
                firstname: formRef.current.elements['contact_firstname'].value,
                lastname: formRef.current.elements['contact_lastname'].value,
                phone: formRef.current.elements['contact_phone'].value,
                email: formRef.current.elements['contact_email'].value
            }
        }
        console.log(data);
        sendToServer(data);
    }

    // At loading
    function getOptionExists(data, optionTypeId) {
        let option = data.options.find(option => option.locationOptionType == optionTypeId);
        let exists = (option != null);
        return (exists);
    }

    // At render
    function getOptionValue(optionTypeId) {
        let option = locationData.options.find(option => option.locationOptionType == optionTypeId);
        if (option != null) {
            return option.optionValue;
        }
        return null;
    }

    return (
        <Container>
            {locationData != null &&
            <>
                <PictureUpload
                    picture_uri={locationData.picture}
                    default_picture={defaultImage}
                    api_uri="location_upload_picture"
                    model_id={locationData.locationId}
                />
                <form className="form-inline" onSubmit={submitHandler} ref={formRef}>
                    <Grid container>
                        <Grid item xs={6} sx={{p: '16px'}}>
                            <Box display="flex" justifyContent="center"
                                 sx={{border: 2, borderRadius: '8px', borderColor: 'orange', p: '8px'}}>
                                {t('location.info.contact')}
                            </Box>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="contact_firstname"
                                label={t('location.info.contact_firstname')}
                                defaultValue={locationData.contact ? locationData.contact.firstName : ''}
                                required
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="contact_lastname"
                                label={t('location.info.contact_lastname')}
                                defaultValue={locationData.contact ? locationData.contact.lastName : ''}
                                required
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="contact_phone"
                                label={t('location.info.contact_phone')}
                                defaultValue={locationData.contact ? locationData.contact.phoneNumber : ''}
                                required
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="contact_email"
                                label={t('location.info.contact_email')}
                                defaultValue={locationData.contact ? locationData.contact.mail : ''}
                                required
                            />
                        </Grid>
                        <Grid item xs={6} sx={{p: '16px'}}>
                            <Box display="flex" justifyContent="center"
                                 sx={{border: 2, borderRadius: '8px', borderColor: 'orange', p: '8px'}}>
                                {t('location.info.location_type')}
                            </Box>

                            <Switch
                                id="private_location"
                                checked={privateLocation}
                                // defaultChecked={getOptionExists(1)}
                                onChange={function (e) {
                                    setPrivateLocation(e.target.checked)
                                }}/> {t('location.info.private_location')}
                            <TextField
                                margin="normal"
                                fullWidth
                                id="private_location_share_code"
                                label={t('location.info.private_location_share_code')}
                                defaultValue={getOptionValue(3)}
                                disabled={!privateLocation}
                            />

                            <Switch
                                id="mobile_location"
                                checked={mobileLocation}
                                onChange={function (e) {
                                    setMobileLocation(e.target.checked)
                                }}/> {t('location.info.mobile_location')}
                            <TextField
                                margin="normal"
                                fullWidth
                                id="mobile_location_distance"
                                label={t('location.info.mobile_location_distance')}
                                disabled={!mobileLocation}
                            />
                        </Grid>
                    </Grid>
                </form>
            </>}
        </Container>
    )
};

export default forwardRef(LocationInfo);