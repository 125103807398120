import {Box, Button, Container, Grid, InputAdornment, Stack, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import DeleteIcon from '@mui/icons-material/Delete';

export default function LocationCustomItem(props) {
    const classes = useStyles();
    const {t} = useTranslation('common');

    const [price, setPrice] = useState(props.priceData.price);

    useEffect(() => {
        if (props.uniquePrice) {
            setPrice(props.uniquePriceValue);
        }
    }, [props.uniquePrice, props.uniquePriceValue]);

    useEffect(() => {
        if (props.priceData) {
            setPrice(props.priceData.price);
        }
    }, [props.priceData]);

    useEffect(() => {
        // Update model
        props.priceData.price = price;
    }, [price]);

    return (
        <Container disableGutters={true} sx={{
            // backgroundColor :'#afbff0',
            border: 1,
            p:2
        }} >
            <Stack pl="0" sx={{
                // backgroundColor :'#FF0000',
            }}>
                <TextField
                    // sx={{mt: '15px'}}
                    type="number"
                    margin="normal"
                    size="small"
                    fullWidth
                    id="label"
                    label={t('location.services.price')}
                    // value={price > 0 ? price : ''}
                    onChange={(e) => {
                        let newPrice = parseFloat(e.target.value);
                        if (newPrice < 0) {
                            newPrice = 0;
                        }
                        setPrice(newPrice);
                        props.priceChangedHandler(e);
                        props.priceData.price = newPrice;
                    }}
                    value={price || ''}
                    // defaultValue={serviceData.label}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{props.currency}</InputAdornment>
                    }}
                />
                <TextField
                    sx={{mb: '20px'}}
                    margin="normal"
                    size="small"
                    fullWidth
                    id="category_label"
                    label={t('location.services.category_label')}
                    onChange={(e) => {
                        props.priceData.categoryName = e.target.value;
                        props.refreshDataHandler();
                    }}
                    value={props.priceData.categoryName}
                />
                {props.canDelete &&
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="red"
                    // sx={{mb: '5px'}}
                    onClick={props.deleteCategoryHandler}
                >
                    <DeleteIcon/>
                </Button>
                }
            </Stack>
        </Container>
    )
}

const useStyles = makeStyles((theme) => ({
    icon: {
        width: '70px'
    },
}));